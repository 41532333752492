import { useContext } from 'react';
import EstatesAndMapsContext from 'contexts/EstatesAndMapsContext';
import { EstatesAndMapDataContextType } from 'types/estatesAndMap';

// ==============================|| ESTATES - HOOKS  ||============================== //

const useEstatesAndMaps = () => {
    const context = useContext(EstatesAndMapsContext) as EstatesAndMapDataContextType; // To ensure data and functions are not unknown when init is null;
    if (context === undefined) throw new Error("useEstatesAndMaps was used outside of its Provider");
    return context;
}

export default useEstatesAndMaps;