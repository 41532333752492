import { useRef, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography,
  // Tab, Tabs,
} from "@mui/material";

// material ui icons
import AccountCircleIcon from "@mui/icons-material/AccountCircleRounded";
import LogoutIcon from "@mui/icons-material/LogoutRounded";

// project import
import { SOFTWARE_VERSION } from "config/config-general";
import Avatar from "components/@extended/Avatar";
import MainCard from "components/MainCard";
import Transitions from "components/@extended/Transitions";
import IconButton from "components/@extended/IconButton";
import useAuth from "hooks/useAuth";
// import ProfileTab from './ProfileTab';
// import SettingTab from './SettingTab';
import { useTranslation } from "utils/locales/utilityFunctions";
import useAppFunctionalityConfig from "hooks/useAppFunctionalityConfig";

// assets
// import avatar1 from 'assets/images/users/avatar-1.png';
// import { SettingOutlined, UserOutlined } from '@ant-design/icons';

// types
// interface TabPanelProps {
//   children?: ReactNode;
//   dir?: string;
//   index: number;
//   value: number;
// }

// tab panel wrapper
// function TabPanel(props: TabPanelProps) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
//       {value === index && children}
//     </div>
//   );
// }

// function a11yProps(index: number) {
//   return {
//     id: `profile-tab-${index}`,
//     'aria-controls': `profile-tabpanel-${index}`
//   };
// }

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  // --- Hooks --- //
  const theme = useTheme();
  const { subConfigName: name } = useAppFunctionalityConfig();
  const { translate } = useTranslation();
  const { logout, user } = useAuth();

  // --- States and refs --- //
  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  // const [value, setValue] = useState(0);

  // --- Functions --- //
  // Handle logout
  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  // const handleChange = (event: SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };

  const iconBackColorOpen = theme.palette.mode === "dark" ? "grey.200" : "primary.lighter";
  let userName = "";
  let userRole = "";
  if (user) {
    userName = user.firstName + " " + (user.lastName ? user.lastName : "");
    userRole = user.orgRole === "admin" ? translate("administrator") : translate("user");
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : "transparent",
          borderRadius: 1,
          "&:hover": {
            bgcolor: theme.palette.mode === "dark" ? "secondary.light" : "secondary.lighter",
          },
          "&:focus-visible": {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2,
          },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={1} alignItems="center" sx={{ p: 0.5, pr: 1, height: 36 }}>
          <Avatar size="sm" alt="profile">
            <AccountCircleIcon fontSize="small" />
          </Avatar>

          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <Typography variant="subtitle1" sx={{ mb: -0.2 }}>
              {userName}
            </Typography>
            <Typography variant="body1" sx={{ fontSize: "12px" }}>
              {translate(`subscription-main-type-${name}`)}
            </Typography>
          </Box>
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 330,
                  minWidth: 240,
                  maxWidth: 330,
                  [theme.breakpoints.down("md")]: {
                    maxWidth: 330,
                  },
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    <CardContent sx={{ px: 2 }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ mr: 1.25 }}>
                          <AccountCircleIcon fontSize="large" color="primary" />
                        </Box>

                        <Box sx={{ mr: 1, flex: 1 }}>
                          <Stack>
                            <Typography variant="h6">{userName}</Typography>

                            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                              {userRole} | {translate(`subscription-main-type-${name}`)}
                            </Typography>
                          </Stack>
                        </Box>

                        <Box>
                          <Tooltip title={translate("logout")}>
                            <IconButton sx={{ color: "text.primary" }} onClick={handleLogout}>
                              <LogoutIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>

                      <Typography variant="body2" color="textSecondary">
                        {translate("software-version")}: {SOFTWARE_VERSION}
                      </Typography>
                    </CardContent>

                    {/* {open && (
                      <>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="profile tabs">
                            <Tab
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textTransform: 'capitalize'
                              }}
                              icon={<UserOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                              label="Profile"
                              {...a11yProps(0)}
                            />
                            <Tab
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textTransform: 'capitalize'
                              }}
                              icon={<SettingOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                              label="Setting"
                              {...a11yProps(1)}
                            />
                          </Tabs>
                        </Box>
                        <TabPanel value={value} index={0} dir={theme.direction}>
                          <ProfileTab handleLogout={handleLogout} />
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                          <SettingTab />
                        </TabPanel>
                      </>
                    )} */}
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
