import React, { Component, ErrorInfo } from 'react';

// material-ui
import { Typography } from '@mui/material';

// project imports
import { ERROR_LOG_ENABLED } from 'config/config';
import MainCard from 'components/MainCard';
import Button from 'components/@extended/LoadingButton';

interface ErrorBoundaryState {
   hasError: boolean;
}

interface ErrorBoundaryProps {
   children: React.ReactNode;
   fallback?: React.ReactNode;
   logErrorFunction?: (error: Error, errorInfo: ErrorInfo) => void;
   translate: (input: string) => string;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
   state: ErrorBoundaryState = { hasError: false };

   static getDerivedStateFromError(_: Error): ErrorBoundaryState {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
   }

   componentDidCatch(error: Error, errorInfo: ErrorInfo) {
      // You can also log the error to an error reporting service
      if (ERROR_LOG_ENABLED && this.props.logErrorFunction) this.props.logErrorFunction(error, errorInfo);
   }

   renderError = () => {
      return <div style={{
         position: 'fixed',
         top: 0,
         left: 0,
         right: 0,
         bottom: 0,
         backgroundColor: 'white',
         zIndex: 1000,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center'
      }}>
         <MainCard title={this.props.translate("error-page-title")} sx={{ maxWidth: 500 }}>
            <Typography sx={{ mb: 2 }}>{this.props.translate("error-page-text")}</Typography>

            <Button
               variant="contained"
               sx={{
                  bgcolor: 'primary.light',
                  '&.Mui-disabled': {
                     bgcolor: 'secondary.lighter',
                     color: 'secondary.main'
                  }
               }}
               onClick={() => window.location.reload()}
            >
               {this.props.translate("error-page-button")}
            </Button>
         </MainCard>
      </div>
   }

   render() {
      if (this.state.hasError) {
         // You can render any custom fallback UI
         return this.props.fallback ? this.props.fallback : this.renderError();
      }

      return this.props.children;
   }
}

export default ErrorBoundary;