import { useIntl } from "react-intl";

// Hook for getting only translated text
export function useTranslation() {
  const intl = useIntl();
  const translate = (key: string) => {
    let testKey = key;
    if (testKey === "") testKey = "empty-key";
    return intl.formatMessage({ id: testKey });
  };

  return { translate };
}

export function mapFromI18nToCountry(i18n: string) {
  switch (i18n) {
    case "da":
      return "dk";
    case "no":
      return "no";
    case "en":
      return "gb";
    case "es":
      return "es";
    case "fr":
      return "fr";
    case "it":
      return "it";
    case "de":
      return "de";
    default:
      return "gb";
  }
}
