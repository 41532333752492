// Org role Config files
import orgNotAdminConfig from "config/appConfigFiles/orgNotAdmin.json";

// Map role Config files
import roleViewerConfig from "config/appConfigFiles/roleViewer.json";
import roleNoteConfig from "config/appConfigFiles/roleNote.json";
import roleEditorConfig from "config/appConfigFiles/roleEditor.json";
import roleAdminConfig from "config/appConfigFiles/roleAdmin.json";

// Project role Config files
import roleProjectViewerConfig from "config/appConfigFiles/roleProjectViewer.json";
import roleProjectNoteConfig from "config/appConfigFiles/roleProjectNote.json";
import roleProjectEditorConfig from "config/appConfigFiles/roleProjectEditor.json";
import roleProjectAdminConfig from "config/appConfigFiles/roleProjectAdmin.json";

// Subscription config files
import defaultConfig from "config/appConfigFiles/default.json";
import freeConfig from "config/appConfigFiles/free.json";
import basicConfig from "config/appConfigFiles/basic.json";
import proConfig from "config/appConfigFiles/pro.json";

// Types
import { RolesProps } from "types/estatesAndMap";
import { RoleTypes, Subscription } from "types/auth";

export function getAppConfigFromMapRole(
  roles: { [key: string]: RolesProps },
  userId: string,
  type: "map" | "project"
) {
  // Return if mapRoles not provided or user id not provided
  if (!roles || !userId) return roleViewerConfig;
  // Return viewer config if user is not registred in the map
  if (!roles[userId]) return roleViewerConfig;

  switch (roles[userId].role) {
    case "viewer": {
      if (type === "map") {
        return roleViewerConfig;
      }
      return roleProjectViewerConfig;
    }
    case "commentator": {
      if (type === "map") {
        return roleNoteConfig;
      }
      return roleProjectNoteConfig;
    }
    case "editor": {
      if (type === "map") {
        return roleEditorConfig;
      }
      return roleProjectEditorConfig;
    }
    case "admin": {
      if (type === "map") {
        return roleAdminConfig;
      }
      return roleProjectAdminConfig;
    }
    default: {
      if (type === "map") {
        return roleViewerConfig;
      }
      return roleProjectViewerConfig;
    }
  }
}

export function getAppConfigFromSubscription(
  subscription: Subscription | undefined,
  userOrgRole?: "admin" | "not-admin"
) {
  let config = freeConfig as any;
  if (!subscription || !subscription.type || subscription.type === "free") {
    if (!userOrgRole || userOrgRole === "not-admin") {
      return { ...config, ...orgNotAdminConfig };
    }
    return config;
  }
  switch (subscription.type) {
    case "basic":
      config = basicConfig;
      break;
    case "pro":
      config = proConfig;
      break;
    case "advanced":
      config = defaultConfig;
      break;
    case "trial":
      config = defaultConfig;
      break;
    default:
      config = defaultConfig;
      break;
  }
  if (!userOrgRole || userOrgRole === "not-admin") {
    return { ...config, ...orgNotAdminConfig };
  }
  return config;
}

export function getAppConfigFromOrgRole(role: RoleTypes) {
  switch (role) {
    case "admin":
      return roleEditorConfig;
    case "not-admin":
      return orgNotAdminConfig;
    default:
      return orgNotAdminConfig;
  }
}
