// material-ui
import { Link, Stack, Typography, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';

// project import
import { useTranslation } from 'utils/locales/utilityFunctions';

const Footer = () => {
  const { translate } = useTranslation();
  const matchDownSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (<Stack
    direction={matchDownSM ? 'column' : 'row'}
    justifyContent={matchDownSM ? 'center' : 'space-between'}
    sx={{ p: '12px 6px 0px', mt: 'auto' }}
    textAlign={matchDownSM ? 'center' : 'inherit'}
    spacing={1}
  >
    <Typography variant="caption">&copy; {translate('all-rights-reserved')}</Typography>

    <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
      <Typography
        variant="subtitle2"
        color="secondary"
        component={Link}
        href="https://www.skovkortet.dk/vores-verden/om-os"
        target="_blank"
        underline="hover"
      >
        {translate('about-us')}
      </Typography>

      <Typography
        variant="subtitle2"
        color="secondary"
        component={Link}
        href="https://skovkortet.dk/policy"
        target="_blank"
        underline="hover"
      >
        {translate('cookies-and-privacy')}
      </Typography>

      <Typography
        variant="subtitle2"
        color="secondary"
        component={Link}
        href="https://skovkortet.dk/brugervilkar-og-betingelser"
        target="_blank"
        underline="hover"
      >
        {translate('terms')}
      </Typography>
    </Stack>
  </Stack>)
};

export default Footer;
