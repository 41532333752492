export default function mapLocationPathToPageView(path: string) {
  const basePath = "App Page View: ";
  const splitStr = path.split("/");
  const mainPath = splitStr[1];
  switch (mainPath) {
    case "":
    case "home-page":
      return basePath + "Home";
    case "map-page":
      return basePath + "Map";
    case "lists-page":
      return basePath + "Lists";
    case "projects-page":
      return basePath + "Projects";
    case "files-page":
      return basePath + "Files";
    case "estates-page":
      return basePath + "Estates";
    case "team-and-contacts-page":
      return basePath + "Team & Contacts";
    case "profile-page":
      return basePath + "Profile";
    case "account-page":
      return basePath + "Account";
    case "register":
      return basePath + "Register";
    case "login":
      return basePath + "Login";
    case "forgot-password":
      return basePath + "Forgot Password";
    case "check-mail":
      return basePath + "Check Mail";
    case "reset-password":
      return basePath + "Reset Password";
    case "anonymous-login":
      return basePath + "Anonymous Login";
    default:
      return basePath + `Unidentified: ${path}`;
  }
}
