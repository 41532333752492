// third-party
import { FormattedMessage } from 'react-intl';

// mui icons
import HomeIcon from '@mui/icons-material/HomeRounded';
import NaturePeopleIcon from '@mui/icons-material/NaturePeopleRounded';
import GroupAddIcon from '@mui/icons-material/GroupAddRounded';
import ManageAccountsIcon from '@mui/icons-material/ManageAccountsRounded';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  HomeIcon,
  NaturePeopleIcon,
  GroupAddIcon,
  ManageAccountsIcon
};

// ==============================|| MENU ITEMS - SETTINGS ||============================== //

const settings: NavItemType = {
  id: 'settings',
  title: <FormattedMessage id="settings" />,
  type: 'group',
  children: [
    {
      id: 'estates-page',
      title: <FormattedMessage id="estates-page" />,
      type: 'item',
      url: '/estates-page',
      icon: icons.NaturePeopleIcon
    },
    {
      id: 'team-and-contacts-page',
      title: <FormattedMessage id="team-and-contacts-page" />,
      type: 'item',
      url: '/team-and-contacts-page',
      icon: icons.GroupAddIcon
    },
    {
      id: 'profile-page',
      title: <FormattedMessage id="profile-page" />,
      type: 'item',
      url: '/profile-page',
      icon: icons.ManageAccountsIcon
    },
    {
      id: 'account-page',
      title: <FormattedMessage id="account-page" />,
      type: 'item',
      url: '/account-page',
      icon: icons.HomeIcon
    }
  ]
};

export default settings;
