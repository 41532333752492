import { useEffect, useRef } from 'react';
import dayjs from 'dayjs'
import 'dayjs/locale/da';
import relativeTime from 'dayjs/plugin/relativeTime'

// Project import
import { UserProfile, UserSettingsObject } from 'types/auth';

dayjs.extend(relativeTime);


interface UseUserActivity {
   user?: UserProfile | null,
   updateUserSettings: (userId: string, data: UserSettingsObject) => Promise<void>,
}
const useUserActivity = ({ user, updateUserSettings }: UseUserActivity) => {
   const userActiveSetRef = useRef(false);

   const setActiveTime = async () => {
      // Set the last active time in users settings doc on firestore
      if (user && user.id) {
         updateUserSettings(user.id, { lastActiveDate: dayjs().format() });
      }
      // Remove the event listeners after the first activity is detected
      window.removeEventListener('mousemove', setActiveTime);
      window.removeEventListener('keydown', setActiveTime);
      window.removeEventListener('click', setActiveTime);
   };

   useEffect(() => {
      if (user && !userActiveSetRef.current) {
         // Add event listeners to capture user activity
         window.addEventListener('mousemove', setActiveTime);
         window.addEventListener('keydown', setActiveTime);
         window.addEventListener('click', setActiveTime);
         userActiveSetRef.current = true;
      }
   }, [user, updateUserSettings]);
};

export default useUserActivity;