export function validateEmail(email: string) {
  const regex = /^[ÆØÅæøåa-zA-Z0-9._%+-]+@[ÆØÅæøåa-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
}

export function existingEmail(email: string, emailArray: string[]): boolean {
  const emailStr = email.toLowerCase();
  for (const str of emailArray) {
    if (str.toLowerCase() === emailStr) return true;
  }
  return false;
}

export function truncLongTextToHtml(text: string) {
  let wordArray = text.split(" ");
  let wordCount = wordArray.length;

  let lineOne = [];
  let lineTwo = [];

  if (wordCount <= 9) {
    for (let i = 0; i < wordCount; i++) {
      lineOne.push(wordArray[i]);
    }
    return lineOne.join(" ");
  } else if (wordCount <= 17) {
    for (let i = 0; i < wordCount; i++) {
      if (i <= 9) {
        lineOne.push(wordArray[i]);
      } else {
        lineTwo.push(wordArray[i]);
      }
    }
    return lineOne.join(" ") + "<br>" + lineTwo.join(" ");
  }
  for (let i = 0; i < wordCount; i++) {
    if (i <= 9) {
      lineOne.push(wordArray[i]);
    } else if (i <= 17) {
      lineTwo.push(wordArray[i]);
    }
  }
  return lineOne.join(" ") + "<br>" + lineTwo.join(" ") + "...";
}

type GetCustomDefaultPath = {
  url: string;
  defaultPath: string;
  defaultPaths: string[];
};
export function getCustomDefaultPath({
  url,
  defaultPath,
  defaultPaths,
}: GetCustomDefaultPath): string {
  const splitUrl = url.split("/");
  const testStr = splitUrl[1];
  let customDefaultPath = defaultPath;
  if (defaultPaths.includes(testStr)) {
    customDefaultPath = `/${testStr}`;
  }
  return customDefaultPath;
}
