// Types
import { OnboardingObject } from "types/auth";

export const mixpanelEvents = {
  mergeDistinctIdAndUserId: "Merge Distinct ID and User ID",
  estateCreated: "Estate Created",
  estateDeleted: "Estate Deleted",
  mapCreated: "Map Created",
  mapDeleted: "Map Deleted",
  projectCreated: "Project Created",
  projectDeleted: "Project Deleted",
  activityCreated: "Activity Created",
  activityDeleted: "Activity Deleted",
  orgToOrgSubscriptionCreated: "Org to Org Subscription Created",
  orgToOrgSubscriptionDeleted: "Org to Org Subscription Deleted",
  noteCreated: "Note Created",
  noteDeleted: "Note Deleted",
  fileUploaded: "File Uploaded",
  fileDownloaded: "File Downloaded",
  fileDeleted: "File Deleted",
  mapLayerMenuOpened: "Map Layer Menu Opened",
  mapFilterMenuOpened: "Map Filter Menu Opened",
  mapFeatureOpened: "Map Feature Opened",
  mapOrProjectShared: "Map or Project Shared",
  teamMemberCreated: "Team Member Created",
  teamMemberDeleted: "Team Member Deleted",
  contactCreated: "Contact Created",
  contactDeleted: "Contact Deleted",
};

export function mapOnboardingStepToMixpanelUserOnbardingStep(onbObj: OnboardingObject): string {
  if (!onbObj || !onbObj.step) return "Step ?: Unknown";
  switch (onbObj.step) {
    case 0:
      return "Step 0: Welcome";
    case 1:
      return "Step 1: Heard about us";
    case 2:
      return "Step 2: Business info";
    case 3:
      return "Step 3: Questionnaire";
    case 4:
      return "Step 4: Finish";
    default:
      return "Step ?: Unknown";
  }
}
