export function interpretPlaceResult(place: google.maps.places.PlaceResult) {
    const { address_components, geometry, name } = place;
    if (!geometry || !geometry.location) return { searchString: name ? name : "", address: null, location: null };
    const address = address_components?.reduce((acc, curr) => {
       const { long_name, types } = curr;
       if (types.includes('street_number')) {
          return { ...acc, streetNumber: long_name }
       } else if (types.includes('route')) {
          return { ...acc, streetName: long_name }
       } else if (types.includes('locality')) {
          return { ...acc, locality: long_name }
       } else if (types.includes('postal_town')) {
         return { ...acc, postalTown: long_name }
       } else if (types.includes('administrative_area_level_1')) {
          return { ...acc, state: long_name }
       } else if (types.includes('postal_code')) {
          return { ...acc, postalCode: long_name }
       } else if (types.includes('country')) {
          return { ...acc, country: long_name }
       } else {
          return acc;
       }
    }, {} as any);
 
    let location = null;
    if (geometry?.location) {
       const lat = geometry.location.lat();
       const lng = geometry.location.lng();
       location = { lat, lng };
    }
 
    return { searchString: name ? name : "", address, location };
 }
 
 export function interpretPlaceDetails(place: string | google.maps.places.PlaceResult) {
    if (typeof place === 'string') {
       return { searchString: place, address: null, businessName: null,  location: null };
    } else {
       let businessData: any = interpretPlaceResult(place);
       if (place.types && place.types.includes('establishment')) {
          businessData = { ...businessData, businessName: place.name };
       }
       return businessData;
    }
 }