// image
import logoIcon from 'assets/images/logo-icon.png';

// ==============================|| LOGO ICON PNG ||============================== //

const LogoIcon = () => {
  return (
    <img src={logoIcon} alt="Forsler" height="35px" />
  );
};

export default LogoIcon;
