import { useContext } from 'react';
import { AppFunctionalityContext } from 'contexts/AppFunctionalityContext';

// ==============================|| CONFIG - HOOKS  ||============================== //
const useAppFunctionalityConfig = () => {
    const context = useContext(AppFunctionalityContext);
    if (context === undefined) throw new Error("useAppFunctionalityConfig was used outside of its Provider");
    
    return context;
}

export default useAppFunctionalityConfig;