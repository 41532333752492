import { useEffect, useState } from "react";

// Project import
import { SOFTWARE_VERSION } from "config/config-general";
import useAuth from "hooks/useAuth";
import ConfirmDialog from "components/dialogs/ConfirmDialog";
import { useTranslation } from "utils/locales/utilityFunctions";

const SoftwareVersionCheck = () => {
  // --- Hooks --- //
  const { currentSoftwareVersion } = useAuth();
  const { translate } = useTranslation();
  // --- State --- //
  const [showSWVersionMismatch, setShowSWVersionMismatch] = useState(false);

  // --- Effects --- //
  // Check software version on cloud vs local
  useEffect(() => {
    if (currentSoftwareVersion !== "" && currentSoftwareVersion !== SOFTWARE_VERSION) {
      setShowSWVersionMismatch(true);
    }
  }, [currentSoftwareVersion]);

  // --- Handlers --- //
  const onConfirmSoftwareVersionMismatch = () => {
    setShowSWVersionMismatch(false);
    window.location.reload();
  };
  // --- Render --- //
  if (showSWVersionMismatch) {
    return (
      <ConfirmDialog
        open={true}
        maxWidth="xs"
        title={translate("software-version-mismatch-title")}
        text={translate("software-version-mismatch-text")}
        onConfirmText={translate("software-version-mismatch-button-confirm-text")}
        onCancelText={translate("close")}
        onCancel={() => setShowSWVersionMismatch(false)}
        onConfirm={onConfirmSoftwareVersionMismatch}
      />
    );
  }

  return null;
};

export default SoftwareVersionCheck;
