import React, { SyntheticEvent } from 'react';

// material ui
import {
   Chip, Box, Autocomplete, TextField, Typography, ListItem
} from "@mui/material";
import { styled } from "@mui/system";

type AutocompleteProps = {
   title: string,
   label: string,
   value?: any[],
   data: any[],
   groupByField?: string,
   renderOptionField: string,
   renderTagField: string,
   showTitle: boolean,
   showLabel: boolean,
   fixedOptions?: any[],
   noOptionsText?: string,
   condensed?: boolean,
   limitTags?: number,
   filterSelectedOptions?: boolean,
   changeHandler: (e: SyntheticEvent<Element, Event>, v: any) => void,
   filterHandler: (options: any, params: any) => any[],
   multiple?: boolean,
   disabled?: boolean,
   className?: string
};

const GroupHeader = styled('div')(({ theme }) => ({
   position: 'sticky',
   top: '-8px',
   padding: '4px 10px',
   backgroundColor: theme.palette.secondary.light
}));

const GroupItems = styled('ul')({
   padding: 0,
});

// ==============================|| AUTOCOMPLETE - CREATABLE ||============================== //

export default function CreatableAutocomplete({ title, showTitle, label, showLabel, data, value, groupByField, renderOptionField, renderTagField, fixedOptions, limitTags, filterSelectedOptions, changeHandler, filterHandler, multiple, disabled, className }: AutocompleteProps) {
   // Setup parameters
   const fixedOptionsIn = fixedOptions ? fixedOptions : [];
   const valueTest = filterSelectedOptions ? value?.map(el => el[renderOptionField]) : null;

   return (
      <>
         {showTitle && <Typography variant="h5" sx={{ mb: 1 }}>
            {title}
         </Typography>}

         <div className={className}>
            <Autocomplete
               multiple={multiple}
               disabled={disabled}
               groupBy={groupByField ? (option) => option[groupByField] : undefined}
               fullWidth
               freeSolo
               limitTags={limitTags ? limitTags : -1}
               value={value}
               onChange={changeHandler}
               filterOptions={filterHandler}
               selectOnFocus
               clearOnBlur
               handleHomeEndKeys
               id="auto-complete-creatable"
               options={data}
               isOptionEqualToValue={(option, value) =>
                  option.label === value.label
               }
               getOptionDisabled={(option) => {
                  if (option.disabled !== undefined) return option.disabled;
                  return false;
               }}
               getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                     return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                     return option.inputValue;
                  }
                  // Regular option
                  return option.label;
               }}
               //renderInput={(params) => <TextField {...params} placeholder={disabled ? undefined : label} label={showLabel ? (disabled ? null : label) : null} sx={{ '& .MuiFormLabel-root': { fontSize: 14.5 } }} />}
               renderInput={(params) => <TextField
                  {...params}
                  placeholder={label}
                  label={showLabel ? title : null}
                  sx={{ '& .MuiFormLabel-root': { fontSize: 14 } }}
                  color="secondary"
               />}
               renderGroup={(params) => (
                  // <li key={params.key} style={{ marginTop: 12 }}>
                  <li key={params.key} style={{ marginTop: 0 }}>
                     <GroupHeader sx={{ bgcolor: 'seconday.darker', zIndex: 1 }}>
                        <Typography variant="subtitle2" color="textPrimary" sx={{ fontSize: 16 }}>
                           {params.group}
                        </Typography>
                     </GroupHeader>
                     {/* <GroupItems sx={{ mt: 0, mb: 0 }}>{params.children}</GroupItems> */}
                     <GroupItems sx={{ mt: 0, mb: 1.5 }}>{params.children}</GroupItems>
                  </li>
               )}
               // renderOption={(props, option) => (valueTest && valueTest.includes(option[renderOptionField])) ? null : <li {...props}>{option[renderOptionField]}</li>}
               renderOption={(props, option: any) => (
                  valueTest && valueTest.includes(option[renderOptionField])
               ) ? null : <ListItem {...props} sx={{
                  '&&:hover': {
                     backgroundColor: 'primary.lighter',
                  }
               }}>{option[renderOptionField]}</ListItem>}
               ListboxComponent={React.forwardRef((props, ref) => (
                  // <Box {...props} ref={ref} style={{ maxHeight: '80vh', overflow: 'auto', marginTop: groupByField ? -12 : 0 }} />
                  <Box {...props} ref={ref} />
               ))}
               renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                     <Chip label={option[renderTagField]} {...getTagProps({ index })} disabled={disabled || fixedOptionsIn.indexOf(option) !== -1} />
                  ))
               }
               sx={{
                  '& .MuiOutlinedInput-root': {
                     p: 0.4,
                     height: multiple ? null : 41,
                  },
                  '& .MuiFormLabel-root': {
                     marginTop: "-5px",
                  },
                  '& .MuiInputLabel-shrink': {
                     marginTop: "0px",
                  },
                  '& .MuiAutocomplete-tag': {
                     bgcolor: 'primary.lighter',
                     borderRadius: 1,
                     height: 28,
                     pl: 1.5,
                     pr: 1.5,
                     lineHeight: '28px',
                     '& .MuiChip-label': {
                        paddingLeft: 0,
                        paddingRight: 0
                     },
                     '& .MuiSvgIcon-root': {
                        color: 'primary.main',
                        ml: 1,
                        mr: -0.75,
                        '&:hover': {
                           color: 'primary.main',
                        }
                     }
                  }
               }}
            />
         </div>
      </>
   );
}
