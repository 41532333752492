import { Box } from "@mui/material";

// ------------ LINE ------------
type LineStyle = 'Solid' | 'Dashed';

export const LINE_OPTIONS_NON_PROGRAM_DEFINED = [
   { id: "Solid", label: "line-solid" },
   { id: "Dashed", label: "line-dashed" }
]

export const getLineComponentById = (lineStyle: LineStyle, dimension: number, lineColor: string): JSX.Element | null => {
   const containerStyle = { display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: dimension, width: dimension }
   const style = {
      height: 5, bgcolor: lineColor, border: 1, borderColor: 'secondary.400',
      width: lineStyle === 'Dashed' ? 6 : dimension
   }

   // LINESTYLES
   // Road
   // Solid - #ffffff
   // ForestRoad
   // Solid - #e5b266
   // Track
   // Dashed - #e5b266
   // Trail
   // Dashed - #e5b266
   // WaterStream
   // Solid - #c2eaff
   // Ditch
   // Dashed - #c2eaff

   switch (lineStyle) {
      case 'Solid':
         return <Box sx={containerStyle}><Box sx={{ ...style }} /></Box>;
      case 'Dashed':
         return <Box sx={containerStyle}><Box sx={{ ...style }} /><Box sx={{ ...style }} /></Box>;
      //   case 'Road':
      //       return <Box sx={containerStyle}><Box sx={{ ...style, height: 8 }} /></Box>;
      //   case 'ForestRoad':
      //       return <Box sx={containerStyle}><Box sx={{ ...style, height: 6 }} /></Box>;
      //   case 'Track':
      //       return <Box sx={containerStyle}><Box sx={{ ...style, height: 3 }} /><Box sx={{ ...style, height: 3 }} /></Box>;
      //   case 'Trail':
      //       return <Box sx={containerStyle}><Box sx={{ ...style, height: 2 }} /><Box sx={{ ...style, height: 2 }} /></Box>;
      //   case 'WaterStream':
      //       return <Box sx={containerStyle}><Box sx={{ ...style, height: 6 }} /></Box>;
      //   case 'Ditch':
      //       return <Box sx={containerStyle}><Box sx={{ ...style, height: 2 }} /><Box sx={{ ...style, height: 2 }} /></Box>;
      default:
         return null;
   }
}

// ------------ COLOR ------------
export type ColorStyle =
   'Red' |
   'Pink' |
   'Purple' |
   'Deep Purple' |
   'Indigo' |
   'Blue' |
   'Light Blue' |
   'Cyan' |
   'Teal' |
   'Green' |
   'Light Green' |
   'Lime' |
   'Yellow' |
   'Amber' |
   'Orange' |
   'Deep Orange' |
   'Brown' |
   'Grey' |
   'Blue Grey' |
   'Black' |
   'White';

export const COLOR_OPTIONS = [
   { id: "Red", label: "color-red" },
   { id: "Pink", label: "color-pink" },
   { id: "Purple", label: "color-purple" },
   { id: "Deep Purple", label: "color-deep-purple" },
   { id: "Indigo", label: "color-indigo" },
   { id: "Blue", label: "color-blue" },
   { id: "Light Blue", label: "color-light-blue" },
   { id: "Cyan", label: "color-cyan" },
   { id: "Teal", label: "color-teal" },
   { id: "Green", label: "color-green" },
   { id: "Light Green", label: "color-light-green" },
   { id: "Lime", label: "color-lime" },
   { id: "Yellow", label: "color-yellow" },
   { id: "Amber", label: "color-amber" },
   { id: "Orange", label: "color-orange" },
   { id: "Deep Orange", label: "color-deep-orange" },
   { id: "Brown", label: "color-brown" },
   { id: "Grey", label: "color-grey" },
   { id: "Blue Grey", label: "color-blue-grey" },
   { id: "Black", label: "color-black" },
   { id: "White", label: "color-white" }
]

export const getColorComponentById = (colorStyle: ColorStyle): string => {
   switch (colorStyle) {
      case 'Red':
         return '#F44336'
      case 'Pink':
         return '#E91E63'
      case 'Purple':
         return '#9C27B0'
      case 'Deep Purple':
         return '#673AB7'
      case 'Indigo':
         return '#3F51B5'
      case 'Blue':
         return '#2196F3'
      case 'Light Blue':
         return '#03A9F4'
      case 'Cyan':
         return '#00BCD4'
      case 'Teal':
         return '#009688'
      case 'Green':
         return '#4CAF50'
      case 'Light Green':
         return '#8BC34A'
      case 'Lime':
         return '#CDDC39'
      case 'Yellow':
         return '#FFEB3B'
      case 'Amber':
         return '#FFC107'
      case 'Orange':
         return '#FF9800'
      case 'Deep Orange':
         return '#FF5722'
      case 'Brown':
         return '#795548'
      case 'Grey':
         return '#9E9E9E'
      case 'Blue Grey':
         return '#607D8B'
      case 'Black':
         return '#000000'
      case 'White':
         return '#FFFFFF'
      default:
         return '#000000';
   }
}

// ------------ WIDTH ------------
export const WIDTH_OPTIONS = [
   //  { id: "0", label: "0" },
   { id: "1", label: "1" },
   { id: "2", label: "2" },
   { id: "3", label: "3" },
   { id: "4", label: "4" },
   { id: "5", label: "5" },
   { id: "6", label: "6" },
   { id: "8", label: "8" },
   { id: "10", label: "10" },
   { id: "12", label: "12" },
   { id: "14", label: "14" },
   { id: "16", label: "16" },
   { id: "18", label: "18" },
   { id: "20", label: "20" }
];