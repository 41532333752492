import { useState, SyntheticEvent, useEffect } from "react"

// material ui
import {
   Divider, Box, Grid, Typography, TextField, createFilterOptions, Switch, Stack,
   //Link, 
   Chip
} from '@mui/material';
import { SelectChangeEvent } from "@mui/material/Select";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// project import
import { APP_URL } from "config/config";
import { DEFAULT_CATEGORIES, DISABLE_AUDIENCE } from "config/config-general";
import FormDialog from 'components/dialogs/FormDialog';
import Selector from 'components/select/selector';
import { useTranslation } from 'utils/locales/utilityFunctions';
import { validateEmail } from "utils/textModification";
import AutoCompleteCreatable from "components/input/autoCompleteCreatable";
import AutoCompleteTags from 'components/input/autoCompleteTags';

import { EstateProps, MapProps, ProjectProps } from "types/estatesAndMap";
import { RoleTypes, UserProfile } from "types/auth";
import AutoCompleteGrouped from "components/input/autoCompleteGrouped";
import dayjs from "dayjs";
import 'dayjs/locale/da';
import { getShortestNestedArrayObject } from "utils/dataManipulation";
import { ChipData } from "types/list";

export type InfoElementProps = {
   id: string,
   label: string,
}

export interface ContactType {
   personType?: "team" | "contact" | "audience",
   name?: string,
   email?: string,
   id?: string,
   role?: RoleTypes,
   label: string,
   inputValue?: string,
   status?: string,
   date?: string | null,
   categories?: string[] | null,
   ignoreMaps?: ChipData[],
   ignoreProjects?: ChipData[],
}

interface CategoriesType {
   id: string,
   label: string,
}

type ShareDialogProps = {
   open: boolean,
   user: UserProfile,
   withDataSelector: boolean,
   withPersonSelector: boolean,
   withAudience: boolean,
   loading?: boolean,
   dataType: null | "map" | "estate" | "project",
   allRoleOptions: { id: RoleTypes, status: string, label: string }[],
   dataOptions: { list: any[], uniqueEstates: string[], estateIdsAndNames?: { [key: string]: string } },
   selectedPerson?: null | ContactType,
   teamList?: null | ContactType[],
   contactList?: null | ContactType[],
   selectedData?: null | EstateProps | MapProps | ProjectProps,
   estateMaps?: null | MapProps[],
   estateProjects?: null | ProjectProps[],
   categoriesFromData?: null | string[],
   handleChangeSelectedData?: (data: any | null) => void,
   handleClose: () => void,
   handleSave: (persons: ContactType[], data: EstateProps | MapProps | ProjectProps) => void,
}

const filterContacts = createFilterOptions<ContactType>();

const ShareDialog = ({ open, user, withDataSelector, withPersonSelector, withAudience, loading, dataType, allRoleOptions, dataOptions, selectedPerson, teamList, contactList, selectedData, estateMaps, categoriesFromData, handleClose, handleSave, handleChangeSelectedData }: ShareDialogProps) => {
   // --- Hooks --- //
   const { translate } = useTranslation();
   const sendInviteText = selectedPerson ? translate("share") : translate("send-invite");

   // --- Data --- //
   const TEAM_DATA: ContactType[] = teamList ? teamList as ContactType[] : [];
   const CONTACTS_DATA: ContactType[] = contactList ? contactList as ContactType[] : [];
   const DATA = [...TEAM_DATA, ...CONTACTS_DATA];
   const CATEGORIES_DATA: CategoriesType[] = categoriesFromData ? categoriesFromData.map(el => {
      let label = el;
      if ((DEFAULT_CATEGORIES as unknown as string[]).includes(el)) {
         label = translate("tag-" + el);
      }
      return { id: el, label: label };
   }) : [];

   let title = translate("share");
   if (!withDataSelector && selectedData && selectedData.name) {
      title = selectedData.name;
   }

   // --- State --- //
   const [roleOptions, setRoleOptions] = useState<{ id: RoleTypes, label: string }[]>([]);
   const [role, setRole] = useState<RoleTypes>(selectedPerson && selectedPerson.role ? selectedPerson.role : allRoleOptions[0].id);
   const [date, setDate] = useState<Date | null>(null);
   const [audienceToggle, setAudienceToggle] = useState(selectedData ? selectedData.audienceActive : false);
   const [disableSendButton, setDisableSendButton] = useState(true);
   const [categoriesActive, setCategoriesActive] = useState(false);
   const [dateActive, setDateActive] = useState(false);
   const [chosenContacts, setChosenContacts] = useState<ContactType[]>(selectedPerson ? [selectedPerson] : [])
   const [chosenData, setChosenData] = useState<any[]>([])
   const [categories, setCategories] = useState<CategoriesType[]>([]);
   const [publicUrls, setPublicUrls] = useState<{ title: string, url: string }[]>([]);

   // --- Effects --- //
   useEffect(() => {
      if (!open) {
         resetState();
      }
   }, [open])
   // Set public urls based on type
   useEffect(() => {
      if (dataType === "estate" && estateMaps) {
         setPublicUrls(estateMaps.map(map => {
            let url = APP_URL + `?audience&page=map-page&map=${map.id}&map-name=${map.name}`;
            if (DISABLE_AUDIENCE) {
               url = "..." + url.substring(27);
            }
            return {
               title: map.name,
               url: url,
            }
         }));
      } else if (dataType === "map" && selectedData) {
         let url = APP_URL + `?audience&page=map-page&map=${selectedData.id}&map-name=${selectedData.name}`;
         if (DISABLE_AUDIENCE) {
            url = "..." + url.substring(27);
         }
         setPublicUrls([{
            title: selectedData.name,
            url: url,
         }])
      }

   }, [dataType, estateMaps])

   useEffect(() => {
      if (!open) {
         if (chosenData.length > 0) setChosenData([]);
         if (chosenContacts.length > 0) setChosenContacts([]);
         if (role !== allRoleOptions[0].id) setRole(allRoleOptions[0].id);
      }
   }, [open])

   useEffect(() => {
      if (withDataSelector) {
         if (chosenData.length > 0) {
            if (disableSendButton) {
               setDisableSendButton(false);
            }
         } else {
            if (!disableSendButton) {
               setDisableSendButton(true);
            }
         }
      } else {
         if (chosenData.length > 0) {
            setChosenData([]);
         }
         if (selectedPerson && selectedData) {
            if (selectedPerson.personType === "contact") {
               // Check if contact is represented in the selected data roles
               if (selectedPerson.status !== "invite-pending" && selectedData.roles && selectedPerson.id && selectedData.roles[selectedPerson.id]) {
                  // Check for categories in data
                  if (selectedData.roles[selectedPerson.id].categories) {
                     const catIn = selectedData.roles[selectedPerson.id].categories ? selectedData.roles[selectedPerson.id].categories : [];
                     const cat: CategoriesType[] = catIn ? catIn.map(el => {
                        let label = el;
                        if ((DEFAULT_CATEGORIES as unknown as string[]).includes(el)) {
                           label = translate("tag-" + el);
                        }
                        return { id: el, label: label }
                     }) : [];
                     setCategories(cat)
                  }
                  // Check for expiredate in data
                  if (selectedData.roles[selectedPerson.id].expireDate) {
                     setDate(selectedData.roles[selectedPerson.id].expireDate ? new Date(selectedData.roles[selectedPerson.id].expireDate as string) : null);
                  }
               } else {
                  if (selectedPerson.categories) {
                     const cat: CategoriesType[] = selectedPerson.categories.map(el => {
                        let label = el;
                        if ((DEFAULT_CATEGORIES as unknown as string[]).includes(el)) {
                           label = translate("tag-" + el);
                        }
                        return { id: el, label: label }
                     });
                     setCategories(cat);
                  }
                  if (selectedPerson.date) {
                     setDate(new Date(selectedPerson.date));
                  }
                  if (selectedPerson.role) {
                     setRole(selectedPerson.role);
                  }
               }
               if (!categoriesActive) setCategoriesActive(true);
               if (!dateActive) setDateActive(true);
            }
            if (disableSendButton) {
               setDisableSendButton(false);
            }
            if (selectedData.roles && selectedPerson.id && selectedData.roles[selectedPerson.id] && selectedData.roles[selectedPerson.id].role) {
               setRole(selectedData.roles[selectedPerson.id].role);
            }
         }
      }
      if (selectedData) {
         // Check for selected data type (estate, map, project)
         let roleOptions = [allRoleOptions[0]];
         if (dataType === "estate") {
            const mapRoles = dataOptions.list.filter(el => el.estateId === selectedData.id && el.mapId);
            const selDataOpt = getShortestNestedArrayObject(mapRoles, "roleOptions");
            if (selDataOpt) roleOptions = selDataOpt.roleOptions;
         } else if (dataType === "map") {
            // Set role options according to current user role
            const selDataOpt = dataOptions.list.filter(el => el.mapId === selectedData.id);
            if (selDataOpt.length > 0) roleOptions = selDataOpt[0].roleOptions;
         } else if (dataType === "project") {
            const selDataOpt = dataOptions.list.filter(el => el.projectId === selectedData.id);
            if (selDataOpt.length > 0) roleOptions = selDataOpt[0].roleOptions;
         }
         // Check for selected person and pserson type
         if (selectedPerson && selectedPerson.personType === "contact") {
            if (!categoriesActive) setCategoriesActive(true);
            if (!dateActive) setDateActive(true);
         } else {
            if (categoriesActive) setCategoriesActive(false);
            if (dateActive) setDateActive(false);
         }
         setRoleOptions(roleOptions);
         if (selectedData.audienceActive !== audienceToggle) {
            setAudienceToggle(selectedData.audienceActive);
         }
      } else {
         setRoleOptions([allRoleOptions[0]]);
      }
   }, [withDataSelector, selectedPerson, selectedData, chosenData])

   // --- Functions --- //
   const resetState = () => {
      setRole(allRoleOptions[0].id);
      setDate(null);
      setAudienceToggle(false);
      setDisableSendButton(true);
      setCategoriesActive(false);
      setDateActive(false);
      setChosenContacts([]);
      setChosenData([]);
      setCategories([]);
   }

   const closeHandler = () => {
      setDisableSendButton(true);
      handleClose();
   }

   const shareAction = async () => {
      // Check if person sharing data has categories and expire date
      // Set same data to the people he is sharing with
      let catIn = categories ? categories.map(el => el.id) : null;
      let dateIn = date ? dayjs(date).format() : null;
      if (selectedPerson && user && selectedPerson.id !== user.id) {
         if (selectedData?.roles && selectedData.roles[user.id!]) {
            catIn = (selectedData.roles[user.id!].categories && selectedData.roles[user.id!].categories!.length > 0) ? selectedData.roles[user.id!].categories as string[] : catIn;
            dateIn = selectedData.roles[user.id!].expireDate ? selectedData.roles[user.id!].expireDate as string : dateIn;
         }
      }
      if (selectedPerson && selectedData) {
         const persons: ContactType[] = [
            {
               ...selectedPerson,
               inputValue: audienceToggle ? "true" : "false",
               role: role,
               date: dateIn ? dayjs(dateIn).format() : null,
               categories: catIn,
            }
         ];
         handleSave(persons, { ...selectedData });
      } else if (selectedData) {
         const persons: ContactType[] = [
            ...chosenContacts.map(el => {
               return {
                  ...el,
                  role: role,
                  date: dateIn ? dayjs(dateIn).format() : null,
                  categories: catIn
               }
            }),
            { personType: "audience", label: "audience", inputValue: audienceToggle ? "true" : "false", role: role }
         ]
         handleSave(persons, { ...selectedData });
      }
   }

   const handleChangeShareData = (e: any, v: any) => {
      // Check for values i the select array
      if (handleChangeSelectedData) {
         if (v.length === 0) {
            handleChangeSelectedData(null);
            setChosenData([]);
            return;
         }
         const lastItem = v.slice(-1)[0];
         handleChangeSelectedData(lastItem);
         setChosenData([lastItem]);
      }
   }

   const handleChangeRole = (e: SelectChangeEvent<string>) => {
      setRole(e.target.value as RoleTypes);
   }

   const handleChangeContactPerson = (e: SyntheticEvent<Element, Event>, v: ContactType[]) => {
      // Check incomming value
      const inArray = [...v];
      const lastEntry = inArray.pop();
      if (v.length === 0) {
         setDisableSendButton(true);
      }
      if (lastEntry && lastEntry.inputValue === user.email) {
         setChosenContacts(inArray);
      } else if (lastEntry && !lastEntry.label) {
         if (inArray.length > 0) {
            setDisableSendButton(false);
         }
         setChosenContacts(inArray);
      } else {
         if (v.length > 0) {
            setDisableSendButton(false);
         }
         setChosenContacts(v);
      }
      // Check for contacts in value array
      setCategoriesActive(false);
      setDateActive(false);
      for (const person of v) {
         if (person.personType === "contact") {
            setCategoriesActive(true);
            setDateActive(true);
            break;
         }
      }
   }

   const handleContactFilter = (options: any, params: any) => {
      const filtered = filterContacts(options, params);
      const { inputValue } = params;
      // Check for existing option
      const isExisting = options.some((option: ContactType) => inputValue === option.email);
      // Check input to be email address
      const validEmail = validateEmail(inputValue);
      if (validEmail && !isExisting) {
         filtered.push({
            inputValue,
            email: inputValue,
            label: inputValue,
            name: inputValue,
            role: role,
            personType: "contact",
         })
      }
      return filtered;
   }

   const handleChangeCategories = (e: SyntheticEvent<Element, Event>, v: any) => {
      setCategories(v)
   }


   const handleChangeAudienceToggle = (e: SyntheticEvent<Element, Event>, v: any) => {
      setDisableSendButton(false);
      setAudienceToggle(v);
   }

   // --- Return JSX --- //
   return (
      <FormDialog
         open={open}
         title={title}
         onClickText={sendInviteText}
         onCancelText={translate("cancel")}
         onClick={shareAction}
         onCancel={closeHandler}
         saveDisabled={disableSendButton}
         loading={loading}
         maxWidth='sm'
      >
         <>
            {/* Data selector */}
            {withDataSelector && <Box>
               <Typography variant="h5" sx={{ mb: 2 }}>
                  {translate("share-map")}
               </Typography>

               <Grid container spacing={2}>
                  <Grid item xs={12}>
                     <AutoCompleteGrouped
                        title="title"
                        label={translate("choose-estate-map")}
                        showTitle={false}
                        showLabel={false}
                        data={dataOptions.list}
                        groupByField={"estateId"}
                        groupByFieldToLabel={dataOptions.estateIdsAndNames}
                        renderOptionField={"mapName"}
                        renderTagField={"tagField"}
                        noOptionsText={translate("no-results")}
                        changeHandler={handleChangeShareData}
                        value={chosenData}
                        condensed={false}
                        multiple={true}
                     />
                  </Grid>
               </Grid>
            </Box>}

            {/* Person and role selector */}
            {withPersonSelector && <Box sx={{ flexGrow: 1, mt: 2 }}>
               <Typography variant="h5" sx={{ mb: 2 }}>
                  {translate("share-with-team-contacts")}
               </Typography>

               <Grid container spacing={2}>
                  <Grid item xs={8}>
                     <AutoCompleteCreatable
                        title={translate("input-email-or-name")}
                        label={translate("input-email-or-name")}
                        showLabel={true}
                        showTitle={false}
                        groupByField="personTypeLabelText"
                        // groupByField="personTypeLabel"
                        renderOptionField="name"
                        renderTagField="name"
                        data={DATA}
                        value={selectedPerson ? [selectedPerson] : chosenContacts}
                        changeHandler={handleChangeContactPerson}
                        filterHandler={handleContactFilter}
                        multiple={true}
                        disabled={selectedPerson ? true : false}
                        filterSelectedOptions={true}
                     />
                  </Grid>

                  <Grid item xs={4}>
                     <Selector
                        title={translate("role")}
                        value={role}
                        options={roleOptions}
                        label={true}
                        fullWidth={true}
                        changeHandler={handleChangeRole}
                        required={true}
                        disabled={(selectedPerson ? false : chosenContacts.length === 0) || !selectedData}
                     />
                  </Grid>

                  {categoriesActive && <Grid item xs={8}>
                     <AutoCompleteTags
                        title={translate('area-tags')}
                        label={translate('area-tags')}
                        value={categories}
                        data={CATEGORIES_DATA}
                        changeHandler={handleChangeCategories}
                        showLabel={true}
                        noOptionsText={translate('no-more-options-in-list')}
                        multiple={true}
                     />
                  </Grid>}

                  {dateActive && <Grid item xs={4}>
                     <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjs.locale('da')}>
                        <DatePicker
                           label={translate("expire-date")}
                           value={date}
                           onChange={(newValue) => {
                              setDate(newValue);
                           }}
                           renderInput={(params) => <TextField
                              color="secondary"
                              {...params}
                              sx={{
                                 '& .MuiFormLabel-root': { fontSize: 14.5 },
                                 "& label": {
                                    marginTop: date !== null ? "0px" : "-5px",
                                    "&.Mui-focused": {
                                       marginTop: "0px"
                                    },
                                    color: 'secondary.600'
                                 }
                              }}
                           />}
                        />
                     </LocalizationProvider>
                  </Grid>}
               </Grid>
            </Box>}

            {withAudience && dataType && <>
               <Divider sx={{ my: 2 }} />

               <Box sx={{ flexGrow: 1 }}>
                  <Stack direction={"row"} gap={1}>
                     <Typography variant="h5" sx={{ mb: 1 }}>
                        {translate("share-audience-map")}
                     </Typography>

                     <Chip
                        color='primary'
                        size='small'
                        label='Q4-23'
                     />
                  </Stack>

                  <Grid container spacing={2}>
                     <Grid item xs={10}>
                        {publicUrls.map((el, idx) => {
                           return (
                              <Box key={idx} sx={{ mb: 1 }}>
                                 <Stack spacing={0}>
                                    <Typography variant="subtitle1">{el.title}</Typography>
                                    <Typography variant="caption" color="secondary">
                                       {el.url}
                                    </Typography>
                                 </Stack>
                              </Box>
                           )
                        })}
                     </Grid>

                     <Grid item xs={2}>
                        <Box sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'flex-end' }}>
                           <Box sx={{ borderLeft: "1px solid #D8D8D8" }}>
                              <Switch disabled={DISABLE_AUDIENCE} checked={audienceToggle} onChange={handleChangeAudienceToggle} sx={{ ml: 2, mt: 0.6 }} />
                           </Box>
                        </Box>
                     </Grid>
                  </Grid>
               </Box>
            </>}
         </>
      </FormDialog >
   )
}

export default ShareDialog