import { useEffect, useMemo, useRef } from "react";
import { useIntl } from "react-intl";

import { getGroundArea, getSpecieVolume, simulate } from "@foresting-tomorrow/simulation";

// Project import
import useAuth from "./useAuth";
import { ColorStyle, getColorComponentById } from "components/select/lineUtility";
import { FeatureStyleObject, FirestoreStandProps } from "types/firestoreFeatures";
import { SimulateSpeciesProps } from "types/stand";
import { EstimationModels, ThinningData } from "types/simulation";
import dayjs from "dayjs";

// Default values
const DEFAULT_SPC_COLOR = "#F2F2F2";
const DEFAULT_LINE_COLOR = "#F2F2F2";
const DEFUALT_FOLIAGE_SPC_MODEL_TYPE = "Ahorn - ÆR";
const DEFUALT_NEEDLE_SPC_MODEL_TYPE = "Nobilis - NOB";
const LINE_CUSTOM_ORDER = [
  "Road",
  "Forest Road",
  "Track",
  "Trail",
  "Stone and earth dikes",
  "WaterStream",
  "Ditch",
];
const NATURE_CUSTOM_ORDER = [
  "Meadow",
  "Protected area",
  "Heath",
  "Bog",
  "Pasture",
  "Coastal meadow",
  "Lake",
];

const useSpeciesData = () => {
  // --- Hooks --- //
  const { species, speciesSchemes, selectedSpeciesSchemeId } = useAuth();
  const { locale } = useIntl();

  // --- References and state --- //
  const langRef = useRef(locale);
  const speciesListRef = useRef(species);

  useEffect(() => {
    let updatedSpecies = { ...species };
    if (speciesSchemes && selectedSpeciesSchemeId) {
      if (
        speciesSchemes[selectedSpeciesSchemeId] &&
        speciesSchemes[selectedSpeciesSchemeId].overwrites
      ) {
        Object.entries(speciesSchemes[selectedSpeciesSchemeId].overwrites).forEach(spc => {
          const [key, value] = spc;
          if (updatedSpecies[key]) {
            updatedSpecies[key] = { ...updatedSpecies[key], ...value };
          }
        });
      }
    }
    speciesListRef.current = updatedSpecies;
  }, [species, speciesSchemes, selectedSpeciesSchemeId]);

  // --- Effects --- //
  useEffect(() => {
    langRef.current = locale;
  }, [locale]);

  // --- Function --- //
  const getSpeciesAvailability = (species: string) => {
    let availability = "simple";
    if (species === "") return availability;
    try {
      availability = speciesListRef.current![species].availability;
    } catch (error) {
      console.warn("Species not recognised", { species, error });
    }
    return availability;
  };

  const getSpeciesColor = (species: string, styleObject?: FeatureStyleObject): string => {
    let color = DEFAULT_SPC_COLOR;
    if (species === "" && !styleObject) return color;
    try {
      if (species === "") {
        return getColorComponentById(styleObject!.fillColor as ColorStyle);
      }
      color = speciesListRef.current![species].color;
    } catch (error) {
      console.warn("Species not recognised", { species, error });
    }
    return color;
  };

  const getSpeciesModelType = (species: string) => {
    let modelType = DEFUALT_NEEDLE_SPC_MODEL_TYPE;
    if (getSpeciesType(species) === "foliage") modelType = DEFUALT_FOLIAGE_SPC_MODEL_TYPE;
    try {
      modelType = speciesListRef.current![species].estModel
        ? (speciesListRef.current![species].estModel as string)
        : modelType;
    } catch (error) {
      console.warn("Model type not recognised", { error, species });
    }
    return modelType as EstimationModels;
  };

  const getSpeciesType = (species: string) => {
    let type = "other";
    try {
      type = speciesListRef.current![species].type;
    } catch (error) {
      console.warn("Type not recognised", { error, species });
    }
    return type;
  };

  const getSpeciesNameAndAbbreviation = (
    species: string
  ): { name: string; abbreviation: string } => {
    let out = { name: "", abbreviation: "" };
    if (species === "") return out;
    try {
      const found = speciesListRef.current![species].translations.filter(
        (el) => el.language.toLowerCase() === langRef.current
      );
      // Check for chosen language
      if (found.length > 0) {
        return found[0].species;
      }
      // Check for english
      const defaultFound = speciesListRef.current![species].translations.filter(
        (el) => el.language.toLowerCase() === "en"
      );
      if (defaultFound.length > 0) {
        return defaultFound[0].species;
      }
    } catch (error) {
      console.warn("Name and abbreviation not recognised", { error, species });
    }
    return out;
  };

  interface GetSpeciesNameListProps {
    type?: "foliage" | "needle" | "forest-other";
    availability?: "simple" | "extended" | "full";
    currentSpecies?: string;
  }
  const getSpeciesNameList = ({ type, availability, currentSpecies }: GetSpeciesNameListProps) => {
    let list: { id: string; name: string }[] = [];
    try {
      Object.keys(speciesListRef.current!).forEach((spc) => {
        const spcType = getSpeciesType(spc);
        const spcAvailability = getSpeciesAvailability(spc);
        const nameAbr = getSpeciesNameAndAbbreviation(spc);
        let add = true;
        if (availability) {
          if (availability === "simple" && spcAvailability !== "simple") add = false;
          if (availability === "extended" && spcAvailability === "removed") add = false;
          if (currentSpecies && currentSpecies !== "" && currentSpecies === spc) add = true;
        }
        if (type) {
          if (type === "foliage" && spcType !== "foliage") add = false;
          if (type === "needle" && spcType !== "needle") add = false;
          if (type === "forest-other" && spcType !== "forest-other") add = false;
        }
        // Add to list if true
        if (add) list.push({ id: spc, name: nameAbr.name });
      });
    } catch (error) {
      console.warn("Couldn't get name list", { error, species });
    }
    return list;
  };

  // Nature areas
  const getAreaOptions = (type: "other" | "nature", currentSpecies?: string) => {
    const options: { id: string; label: string }[] = [];
    const curSpc = currentSpecies ? currentSpecies : "";
    try {
      Object.keys(speciesListRef.current!).forEach((spc) => {
        if (
         speciesListRef.current![spc].type === type &&
          (speciesListRef.current![spc].availability !== "removed" || speciesListRef.current![spc].id === curSpc)
        ) {
          const nameAbr = getSpeciesNameAndAbbreviation(spc);
          options.push({ id: spc, label: nameAbr.name });
        }
      });
    } catch (error) {
      console.warn("Couldn't create area options", { error });
    }
    if (type === "nature") return sortByCustomOrder(options, NATURE_CUSTOM_ORDER);
    return options.sort((a, b) => a.label.localeCompare(b.label));
  };

  // --- Lines --- //
  const getLineStyle = (name?: string, styleObject?: FeatureStyleObject) => {
    // Set default style
    let style: { color: string; dashArray: string | null; weight: number } = {
      color: DEFAULT_LINE_COLOR,
      dashArray: null,
      weight: 1,
    };
    try {
      // Find color
      if (name && name !== "") {
        style.color = getSpeciesColor(name);
      } else if (styleObject?.lineColor) {
        style.color = getColorComponentById(styleObject.lineColor as ColorStyle);
      }
      // Find dash array
      if (name && name !== "") {
        style.dashArray = speciesListRef.current![name].dashStyle
          ? (speciesListRef.current![name].dashStyle as string)
          : null;
      } else if (styleObject?.lineStyle) {
        if (styleObject?.lineStyle === "Solid") {
          style.dashArray = null;
        } else if (styleObject?.lineStyle === "Dashed") {
          style.dashArray = "20.6";
        }
      }
      // Find weight
      if (styleObject?.lineWidth) {
        style.weight = styleObject.lineWidth as number;
      }
    } catch (error) {
      console.warn("Couldn't find line style returning default", { error, name, styleObject });
    }
    return style;
  };

  const getLineOptions = () => {
    const options: { id: string; label: string }[] = [];
    try {
      Object.keys(speciesListRef.current!).forEach((spc) => {
        if (speciesListRef.current![spc].type === "line" && speciesListRef.current![spc].availability !== "removed") {
          const nameAbr = getSpeciesNameAndAbbreviation(spc);
          options.push({ id: spc, label: nameAbr.name });
        }
      });
    } catch (error) {
      console.warn("Couldn't create line options", { error });
    }
    return sortByCustomOrder(options, LINE_CUSTOM_ORDER);
  };

  const simulateSpecies = ({
    stand,
    horizonYears,
    autoThinningType,
    thinningDataPlanned,
    thinningStartAge,
    thinningInterval,
    thinningPercentage,
  }: SimulateSpeciesProps) => {
    // Check horizon years
    const h = horizonYears ? horizonYears : 10;
    // Get simulation model type
    const data = mapStandDataToSimulationInput(stand);
    if (!data) {
      // console.warn("Couldn't map stand data to simulation input", { stand });
      return null;
    }
    let thinningData: ThinningData = [];
    const THIN_PERCENTAGE = thinningPercentage ? thinningPercentage : 20;
    if (autoThinningType && autoThinningType === "interval") {
      const MIN_THIN_START_AGES = thinningStartAge ? thinningStartAge : 25;
      const THIN_INTERVAL = thinningInterval ? thinningInterval : 5;
      let startYear = dayjs().year();
      // @ts-ignore
      if (data.age && data.age < MIN_THIN_START_AGES) {
        // @ts-ignore
        const diff = MIN_THIN_START_AGES - data.age;
        startYear = startYear + diff;
      }
      thinningData = createThinningArray(startYear, h, THIN_INTERVAL, THIN_PERCENTAGE);
    } else if (autoThinningType === "planned" && thinningDataPlanned) {
      thinningData = thinningDataPlanned;
    }
    // Get quality parameter
    const result = simulate(
      data.species,
      [0, h],
      data.initCondition,
      thinningData,
      false,
      data.quality,
      true,
      autoThinningType === "groundArea",
      THIN_PERCENTAGE
    );
    return result;
  };

  function mapStandDataToSimulationInput(standData: FirestoreStandProps, featureArea?: number) {
    // Unpack parameters
    const { species, year, height, diameter, treeCount, quality, mix } = standData;
    if (typeof year !== "number" || year <= 0) {
      // console.warn("Stand year not valid", year);
      return null;
    }
    if (typeof height !== "number" || height <= 0) {
      // console.warn("Height not valid", height);
      return null;
    }
    if (typeof diameter !== "number" || diameter <= 0) {
      // console.warn("Diameter not valid", diameter);
      return null;
    }
    if (typeof treeCount !== "number" || treeCount <= 0) {
      // console.warn("Tree Count not valid", treeCount);
      return null;
    }
    // Calculate ground area and volume
    const volume =
      getSpecieVolume(getSpeciesModelType(species), { d: diameter / 100, h: height, T: 0, a: 0 })
        .vb * treeCount;
    const groundArea = getGroundArea(diameter / 100, treeCount);
    // Calculate age
    const age = new Date().getFullYear() - year;
    // Check quality
    const q = quality ? quality : 2;
    const initCondition = {
      area: featureArea ? featureArea : 1,
      age: age,
      height: height,
      diameter: diameter / 100,
      treeCount: treeCount,
      volume: isNaN(volume) ? 0.1 : volume,
      groundArea: groundArea,
    };
    return {
      species: getSpeciesModelType(standData.species),
      initCondition,
      quality: q,
    };
  }

  function createThinningArray(
    startYear: number,
    horizon: number,
    thinningInterval: number,
    thinningPercentage: number,
    randomProperbility?: number
  ) {
    const thinningData: ThinningData = [];
    let thinningYear = startYear + thinningInterval;
    const rdp = randomProperbility ? randomProperbility : 0;
    while (thinningYear < startYear + horizon) {
      if (Math.random() > rdp) {
        thinningData.push({
          date: `${thinningYear}`,
          percentageThinning: thinningPercentage,
          treeCountThinning: 0,
          volumeThinning: 0,
        });
      }
      thinningYear += thinningInterval;
    }
    return thinningData;
  }

  // --- Return --- //
  return {
    species,
    getSpeciesColor,
    getSpeciesModelType,
    getSpeciesType,
    getSpeciesNameAndAbbreviation,
    getSpeciesNameList,
    getAreaOptions,
    getLineStyle,
    getLineOptions,
    simulateSpecies,
  };
};

export default useSpeciesData;

// --- sorting algorithms --- //
function sortByCustomOrder(data: { id: string; label: string }[], customOrder: string[]) {
  return data.sort((a, b) => {
    const aIndex = customOrder.indexOf(a.id);
    const bIndex = customOrder.indexOf(b.id);
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;
    return aIndex - bIndex;
  });
}
