import ReactDOMServer from 'react-dom/server';

// MUI icons
import BugReportRounded from '@mui/icons-material/BugReportRounded';
import NightShelterRoundedIcon from '@mui/icons-material/NightShelterRounded';
import WhatshotRoundedIcon from '@mui/icons-material/WhatshotRounded';
import LandscapeRoundedIcon from '@mui/icons-material/LandscapeRounded';
import GrainRoundedIcon from '@mui/icons-material/GrainRounded';
import AcUnitRoundedIcon from '@mui/icons-material/AcUnitRounded';
import AccessibleRoundedIcon from '@mui/icons-material/AccessibleRounded';
import AddAPhotoRoundedIcon from '@mui/icons-material/AddAPhotoRounded';
import AgricultureRoundedIcon from '@mui/icons-material/AgricultureRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import AssistantDirectionRoundedIcon from '@mui/icons-material/AssistantDirectionRounded';
import AssistantPhotoRoundedIcon from '@mui/icons-material/AssistantPhotoRounded';
import AssistantRoundedIcon from '@mui/icons-material/AssistantRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import DiningRoundedIcon from '@mui/icons-material/DiningRounded';
import DirectionsCarFilledRoundedIcon from '@mui/icons-material/DirectionsCarFilledRounded';
import DoDisturbAltRoundedIcon from '@mui/icons-material/DoDisturbAltRounded';
import DoNotStepRoundedIcon from '@mui/icons-material/DoNotStepRounded';
import EggRoundedIcon from '@mui/icons-material/EggRounded';
import ElectricBoltRoundedIcon from '@mui/icons-material/ElectricBoltRounded';
import FmdGoodRoundedIcon from '@mui/icons-material/FmdGoodRounded';
import FollowTheSignsRoundedIcon from '@mui/icons-material/FollowTheSignsRounded';
import FormatAlignJustifyRoundedIcon from '@mui/icons-material/FormatAlignJustifyRounded';
import ForestRoundedIcon from '@mui/icons-material/ForestRounded';
import GamepadRoundedIcon from '@mui/icons-material/GamepadRounded';
import GrassRoundedIcon from '@mui/icons-material/GrassRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import GradeRoundedIcon from '@mui/icons-material/GradeRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import LabelImportantRoundedIcon from '@mui/icons-material/LabelImportantRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import LocalFloristRoundedIcon from '@mui/icons-material/LocalFloristRounded';
import MyLocationRoundedIcon from '@mui/icons-material/MyLocationRounded';
import NaturePeopleRoundedIcon from '@mui/icons-material/NaturePeopleRounded';
import NatureRoundedIcon from '@mui/icons-material/NatureRounded';
import ParkRoundedIcon from '@mui/icons-material/ParkRounded';
import PedalBikeRoundedIcon from '@mui/icons-material/PedalBikeRounded';
import PetsRoundedIcon from '@mui/icons-material/PetsRounded';
import RadioButtonCheckedRoundedIcon from '@mui/icons-material/RadioButtonCheckedRounded';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import ScienceRoundedIcon from '@mui/icons-material/ScienceRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import TextureRoundedIcon from '@mui/icons-material/TextureRounded';
import ViewAgendaRoundedIcon from '@mui/icons-material/ViewAgendaRounded';
import WindowRoundedIcon from '@mui/icons-material/WindowRounded';
import LocalParkingRoundedIcon from '@mui/icons-material/LocalParkingRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded';
import OtherHousesRoundedIcon from '@mui/icons-material/OtherHousesRounded';
import StairsRoundedIcon from '@mui/icons-material/StairsRounded';
import WaterDamageRoundedIcon from '@mui/icons-material/WaterDamageRounded';
import StopCircleRoundedIcon from '@mui/icons-material/StopCircleRounded';
import AlbumRoundedIcon from '@mui/icons-material/AlbumRounded';
import SportsCricketRoundedIcon from '@mui/icons-material/SportsCricketRounded';
import BrokenImageRoundedIcon from '@mui/icons-material/BrokenImageRounded';
import SensorDoorRoundedIcon from '@mui/icons-material/SensorDoorRounded';
import Co2RoundedIcon from '@mui/icons-material/Co2Rounded';
import EmojiNatureRoundedIcon from '@mui/icons-material/EmojiNatureRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import WorkspacesRoundedIcon from '@mui/icons-material/WorkspacesRounded';
import CollectionsRoundedIcon from '@mui/icons-material/CollectionsRounded';
import EventSeatRoundedIcon from '@mui/icons-material/EventSeatRounded';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ArrowBackwardRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { SvgIconStyleObject } from 'types/mapData';

export type IconID =
   'BugReportRounded' |
   'NightShelterRoundedIcon' |
   'LandscapeRoundedIcon' |
   'GrainRoundedIcon' |
   'AcUnitRoundedIcon' |
   'AccessibleRoundedIcon' |
   'AddAPhotoRoundedIcon' |
   'AgricultureRoundedIcon' |
   'AppsOutageRoundedIcon' |
   'AssignmentRoundedIcon' |
   'AssistantDirectionRoundedIcon' |
   'AssistantPhotoRoundedIcon' |
   'AssistantRoundedIcon' |
   'BadgeRoundedIcon' |
   'ConstructionRoundedIcon' |
   'CreateRoundedIcon' |
   'DeleteRoundedIcon' |
   'DiningRoundedIcon' |
   'DirectionsCarFilledRoundedIcon' |
   'DoDisturbAltRoundedIcon' |
   'DoNotStepRoundedIcon' |
   'EggRoundedIcon' |
   'ElectricBoltRoundedIcon' |
   'FmdGoodRoundedIcon' |
   'FollowTheSignsRoundedIcon' |
   'FormatAlignJustifyRoundedIcon' |
   'ForestRoundedIcon' |
   'GamepadRoundedIcon' |
   'GrassRoundedIcon' |
   'GroupRoundedIcon' |
   'GradeRoundedIcon' |
   'HelpRoundedIcon' |
   'LabelImportantRoundedIcon' |
   'LightModeRoundedIcon' |
   'LocalFloristRoundedIcon' |
   'LocationSearchingRoundedIcon' |
   'MyLocationRoundedIcon' |
   'NaturePeopleRoundedIcon' |
   'NatureRoundedIcon' |
   'ParkRoundedIcon' |
   'PedalBikeRoundedIcon' |
   'PetsRoundedIcon' |
   'RadioButtonCheckedRoundedIcon' |
   'ReportProblemRoundedIcon' |
   'ScienceRoundedIcon' |
   'SearchRoundedIcon' |
   'TextureRoundedIcon' |
   'ViewAgendaRoundedIcon' |
   'WindowRoundedIcon' |
   'WhatshotRoundedIcon' |
   'LocalParkingRoundedIcon' |
   'GroupsRoundedIcon' |
   'LocalShippingRoundedIcon' |
   'VideocamRounded' |
   'OtherHousesRounded' |
   'StairsRounded' |
   'WaterDamageRounded' |
   'StopCircleRounded' |
   'AlbumRoundedIcon' |
   'SportsCricketRoundedIcon' |
   'BrokenImageRoundedIcon' |
   'SensorDoorRoundedIcon' |
   'Co2RoundedIcon' |
   'EmojiNatureRoundedIcon' |
   'VideocamRoundedIcon' |
   'OtherHousesRoundedIcon' |
   'StairsRoundedIcon' |
   'WaterDamageRoundedIcon' |
   'StopCircleRoundedIcon' |
   'WorkspacesRoundedIcon' |
   'CollectionsRoundedIcon' |
   'LockRoundedIcon' |
   'EventSeatRoundedIcon' |
   'ArrowUpwardRoundedIcon' |
   'ArrowDownwardRoundedIcon' |
   'ArrowForwardRoundedIcon' |
   'ArrowBackwardRoundedIcon';

export const POINT_OPTIONS = [
   // Start:
   { id: "FmdGoodRoundedIcon", label: "icon-markers-point" },
   { id: "Text", label: "icon-people-text" },

   // Arrows:
   { id: "ArrowUpwardRoundedIcon", label: "icon-arrow-1" },
   { id: "ArrowDownwardRoundedIcon", label: "icon-arrow-2" },
   { id: "ArrowBackwardRoundedIcon", label: "icon-arrow-3" },
   { id: "ArrowForwardRoundedIcon", label: "icon-arrow-4" },

   // Buildings:
   { id: "OtherHousesRoundedIcon", label: "icon-buildings-1" },
   { id: "WaterDamageRoundedIcon", label: "icon-buildings-2" },
   { id: "NightShelterRoundedIcon", label: "icon-buildings-3" },

   // Miscellaneous:
   { id: "ViewAgendaRoundedIcon", label: "icon-miscellaneous-1" },
   { id: "FormatAlignJustifyRoundedIcon", label: "icon-miscellaneous-2" },
   { id: "TextureRoundedIcon", label: "icon-miscellaneous-3" },
   { id: "WindowRoundedIcon", label: "icon-miscellaneous-4" },
   { id: "WorkspacesRoundedIcon", label: "icon-miscellaneous-5" },
   { id: "StopCircleRoundedIcon", label: "icon-miscellaneous-6" },
   { id: "AlbumRoundedIcon", label: "icon-miscellaneous-7" },
   { id: "BrokenImageRoundedIcon", label: "icon-miscellaneous-8" },
   { id: "SensorDoorRoundedIcon", label: "icon-miscellaneous-9" },
   { id: "RadioButtonCheckedRoundedIcon", label: "icon-miscellaneous-10" },
   { id: "GamepadRoundedIcon", label: "icon-miscellaneous-11" },

   // Markers:
   { id: "AssistantPhotoRoundedIcon", label: "icon-markers-1" },
   { id: "AssistantRoundedIcon", label: "icon-markers-2" },
   { id: "ReportProblemRoundedIcon", label: "icon-markers-3" },
   { id: "DoDisturbAltRoundedIcon", label: "icon-markers-4" },
   { id: "AssistantDirectionRoundedIcon", label: "icon-markers-5" },
   { id: "FollowTheSignsRoundedIcon", label: "icon-markers-6" },
   { id: "DoNotStepRoundedIcon", label: "icon-markers-7" },
   { id: "AccessibleRoundedIcon", label: "icon-markers-8" },
   { id: "LocalParkingRoundedIcon", label: "icon-markers-9" },
   { id: "Co2RoundedIcon", label: "icon-markers-10" },
   { id: "LabelImportantRoundedIcon", label: "icon-markers-11" },

   // People:
   { id: "GroupRoundedIcon", label: "icon-people-1" },
   { id: "GroupsRoundedIcon", label: "icon-people-2" },
   { id: "NaturePeopleRoundedIcon", label: "icon-people-3" },

   // Nature:
   { id: "LocalFloristRoundedIcon", label: "icon-nature-1" },
   { id: "GrassRoundedIcon", label: "icon-nature-2" },
   { id: "LandscapeRoundedIcon", label: "icon-nature-3" },
   { id: "GrainRoundedIcon", label: "icon-nature-4" },
   { id: "ForestRoundedIcon", label: "icon-nature-5" },
   { id: "NatureRoundedIcon", label: "icon-nature-6" },
   { id: "ParkRoundedIcon", label: "icon-nature-7" },
   { id: "EmojiNatureRoundedIcon", label: "icon-nature-8" },
   { id: "BugReportRounded", label: "icon-nature-9" },
   { id: "PetsRoundedIcon", label: "icon-nature-10" },
   { id: "WhatshotRoundedIcon", label: "icon-nature-11" },
   { id: "EggRoundedIcon", label: "icon-nature-12" },
   { id: "AcUnitRoundedIcon", label: "icon-nature-13" },
   { id: "LightModeRoundedIcon", label: "icon-nature-14" },
   { id: "ElectricBoltRoundedIcon", label: "icon-nature-15" },

   // Objects:
   { id: "AddAPhotoRoundedIcon", label: "icon-objects-1" },
   { id: "VideocamRoundedIcon", label: "icon-objects-2" },
   { id: "CollectionsRoundedIcon", label: "icon-objects-3" },
   { id: "ScienceRoundedIcon", label: "icon-objects-4" },
   { id: "AssignmentRoundedIcon", label: "icon-objects-5" },
   { id: "CreateRoundedIcon", label: "icon-objects-6" },
   { id: "MyLocationRoundedIcon", label: "icon-objects-7" },
   { id: "DeleteRoundedIcon", label: "icon-objects-8" },
   { id: "DiningRoundedIcon", label: "icon-objects-9" },
   { id: "HelpRoundedIcon", label: "icon-objects-10" },
   { id: "GradeRoundedIcon", label: "icon-objects-11" },
   { id: "SearchRoundedIcon", label: "icon-objects-12" },
   { id: "StairsRoundedIcon", label: "icon-objects-13" },
   { id: "SportsCricketRoundedIcon", label: "icon-objects-14" },
   { id: "ConstructionRoundedIcon", label: "icon-objects-15" },
   { id: "LockRoundedIcon", label: "icon-objects-16" },
   { id: "EventSeatRoundedIcon", label: "icon-objects-17" },

   // Transport:
   { id: "DirectionsCarFilledRoundedIcon", label: "icon-transport-1" },
   { id: "LocalShippingRoundedIcon", label: "icon-transport-2" },
   { id: "AgricultureRoundedIcon", label: "icon-transport-3" },
   { id: "PedalBikeRoundedIcon", label: "icon-transport-4" }
]

export const getIconComponentById = (iconId: IconID, dimension: number, svgStyle?: SvgIconStyleObject): JSX.Element | null => {
   const style = { height: dimension, width: dimension, backgroundColor: "white", color: 'secondary.600' }
   const svgFillColor = (svgStyle && svgStyle.fillColor) ? svgStyle.fillColor : "";
   const svgStrokeColor = (svgStyle && svgStyle.strokeColor) ? svgStyle.strokeColor : "";
   const svgStrokeWidth = (svgStyle && svgStyle.strokeWidth) ? svgStyle.strokeWidth : 0;

   switch (iconId) {
      case 'BugReportRounded':
         return <BugReportRounded sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'NightShelterRoundedIcon':
         return <NightShelterRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'LandscapeRoundedIcon':
         return <LandscapeRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'GrainRoundedIcon':
         return <GrainRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'AcUnitRoundedIcon':
         return <AcUnitRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'AccessibleRoundedIcon':
         return <AccessibleRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'AddAPhotoRoundedIcon':
         return <AddAPhotoRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'AgricultureRoundedIcon':
         return <AgricultureRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'AssignmentRoundedIcon':
         return <AssignmentRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'AssistantDirectionRoundedIcon':
         return <AssistantDirectionRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'AssistantPhotoRoundedIcon':
         return <AssistantPhotoRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'AssistantRoundedIcon':
         return <AssistantRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'ConstructionRoundedIcon':
         return <ConstructionRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'CreateRoundedIcon':
         return <CreateRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'DeleteRoundedIcon':
         return <DeleteRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'DiningRoundedIcon':
         return <DiningRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'DirectionsCarFilledRoundedIcon':
         return <DirectionsCarFilledRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'DoDisturbAltRoundedIcon':
         return <DoDisturbAltRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'DoNotStepRoundedIcon':
         return <DoNotStepRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'EggRoundedIcon':
         return <EggRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'ElectricBoltRoundedIcon':
         return <ElectricBoltRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'FmdGoodRoundedIcon':
         return <FmdGoodRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'FollowTheSignsRoundedIcon':
         return <FollowTheSignsRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'FormatAlignJustifyRoundedIcon':
         return <FormatAlignJustifyRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'ForestRoundedIcon':
         return <ForestRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'GamepadRoundedIcon':
         return <GamepadRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'GrassRoundedIcon':
         return <GrassRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'GroupRoundedIcon':
         return <GroupRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'GradeRoundedIcon':
         return <GradeRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'HelpRoundedIcon':
         return <HelpRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'LabelImportantRoundedIcon':
         return <LabelImportantRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'LightModeRoundedIcon':
         return <LightModeRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'LocalFloristRoundedIcon':
         return <LocalFloristRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'MyLocationRoundedIcon':
         return <MyLocationRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'NaturePeopleRoundedIcon':
         return <NaturePeopleRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'NatureRoundedIcon':
         return <NatureRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'ParkRoundedIcon':
         return <ParkRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'PedalBikeRoundedIcon':
         return <PedalBikeRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'PetsRoundedIcon':
         return <PetsRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'RadioButtonCheckedRoundedIcon':
         return <RadioButtonCheckedRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'ReportProblemRoundedIcon':
         return <ReportProblemRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'ScienceRoundedIcon':
         return <ScienceRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'SearchRoundedIcon':
         return <SearchRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'TextureRoundedIcon':
         return <TextureRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'ViewAgendaRoundedIcon':
         return <ViewAgendaRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'WindowRoundedIcon':
         return <WindowRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'WhatshotRoundedIcon':
         return <WhatshotRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'LocalParkingRoundedIcon':
         return <LocalParkingRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'GroupsRoundedIcon':
         return <GroupsRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'LocalShippingRoundedIcon':
         return <LocalShippingRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'VideocamRoundedIcon':
         return <VideocamRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'OtherHousesRoundedIcon':
         return <OtherHousesRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'StairsRoundedIcon':
         return <StairsRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'WaterDamageRoundedIcon':
         return <WaterDamageRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'StopCircleRoundedIcon':
         return <StopCircleRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'AlbumRoundedIcon':
         return <AlbumRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'SportsCricketRoundedIcon':
         return <SportsCricketRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'BrokenImageRoundedIcon':
         return <BrokenImageRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'SensorDoorRoundedIcon':
         return <SensorDoorRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'Co2RoundedIcon':
         return <Co2RoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'EmojiNatureRoundedIcon':
         return <EmojiNatureRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'WorkspacesRoundedIcon':
         return <WorkspacesRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'LockRoundedIcon':
         return <LockRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'EventSeatRoundedIcon':
         return <EventSeatRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'CollectionsRoundedIcon':
         return <CollectionsRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'ArrowUpwardRoundedIcon':
         return <ArrowUpwardRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'ArrowDownwardRoundedIcon':
         return <ArrowDownwardRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'ArrowBackwardRoundedIcon':
         return <ArrowBackwardRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      case 'ArrowForwardRoundedIcon':
         return <ArrowForwardRoundedIcon sx={style} fill={svgFillColor} stroke={svgStrokeColor} strokeWidth={svgStrokeWidth} />;
      default:
         return null;
   }
}

export function getLeafletIconHtml(iconId: IconID, svgStyle?: SvgIconStyleObject): string | null {
   const iconComp = getIconComponentById(iconId, 24, svgStyle);
   if (iconComp) {
      return ReactDOMServer.renderToString(iconComp);
   } else {
      return null;
   }
} 