// MUI Import
import {
   Box, Dialog, DialogActions,
   DialogContent, DialogTitle, Stack
} from '@mui/material';

// Project import
import Button from "../@extended/LoadingButton"

interface ConfirmDialogProps {
   open: boolean,
   title: string,
   text: string,
   onCancelText?: string,
   onConfirmText?: string,
   matchDownMD?: boolean,
   maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
   invertConfirmActions?: boolean,
   loading?: boolean,
   onConfirm: () => void,
   onCancel?: () => void
}
const ConfirmDialog = (props: ConfirmDialogProps) => {
   return (
      <Dialog
         open={props.open}
         maxWidth={ props.maxWidth ? props.maxWidth : 'sm'}
      >
         <Box sx={{ p: !props.matchDownMD ? 1 : 0 }}>
            <DialogTitle>
               {props.title}
            </DialogTitle>

            <DialogContent dividers>
               {props.text}
            </DialogContent>

            <DialogActions>
               <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ width: 1, px: 1.5, py: 0.75 }}>
                  {/* {buttons} */}
                  {props.onCancel &&  <Button color="secondary" onClick={props.onCancel}>
                     {props.onCancelText ? props.onCancelText : "Cancel"}
                  </Button>}

                  <Button
                     variant="contained"
                     onClick={props.onConfirm}
                     sx={{
                        bgcolor: 'primary.light',
                        '&.Mui-disabled': {
                           bgcolor: 'secondary.lighter',
                           color: 'secondary.main'
                        }
                     }}
                     loading={props.loading}
                  >
                     {props.onConfirmText ? props.onConfirmText : "Confirm"}
                  </Button>
               </Stack>
            </DialogActions>
         </Box>
      </Dialog>
   )
}

export default ConfirmDialog;