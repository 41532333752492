// material-ui
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, FormHelperText, CircularProgress } from '@mui/material';

// material-ui icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardRounded';

type InputWithIconProps = {
    fullWidth: boolean,
    placeholder: string,
    value: string,
    type?: string,
    condensed?: boolean,
    showLabel?: boolean,
    error: boolean,
    disabled?: boolean,
    loading?: boolean,
    helperText?: string | null,
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onClickAction: (event: any) => void,
    disabledAction: boolean
}

const InputWithAction = ({ fullWidth, placeholder, value, type, condensed, showLabel, error, disabled, loading, helperText, handleChange, onClickAction, disabledAction }: InputWithIconProps) => {
    return (
        <FormControl fullWidth={fullWidth} error={error}>
            <InputLabel 
                sx={{
                    mt: "-5px",
                    "&.Mui-focused": {
                        mt: "0px"
                    },
                    "&.MuiInputLabel-shrink": {
                        mt: "0px"
                    }
                }}
                htmlFor={`outlined-label` + placeholder}
                color="secondary"
            >
                {placeholder}
            </InputLabel>

            <OutlinedInput
                id={`end-adornment-action` + placeholder}
                placeholder={placeholder}
                value={value}
                type={type}
                disabled={disabled}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label={`action-button` + placeholder}
                            edge="end"
                            color="primary"
                            onClick={onClickAction}
                            disabled={disabledAction}
                        >
                            {!loading && <ArrowForwardIcon fontSize='small' />}
                            {loading && <CircularProgress size={16} color="secondary" />}
                        </IconButton>
                    </InputAdornment>
                }
                onChange={handleChange}
                color="secondary"
                sx={{
                    bgcolor: 'white',
                    '& .MuiInputBase-input': {
                        fontSize: 14.5,
                        height: condensed ? 15 : null,
                    }
                }}
            />

            <FormHelperText>
                {helperText}
            </FormHelperText>
        </FormControl>
    )
}

export default InputWithAction;
