import { ChangeEvent, useEffect, useRef, useState } from "react";

// material-ui
import {
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  useTheme,
} from "@mui/material";

// material-ui icons
import SearchIcon from "@mui/icons-material/SearchRounded";
import Transitions from "components/@extended/Transitions";

// Project import
import InputWithIcon from "./inputWithIcon";

type PlacesAutocompleteProps = {
  disabled?: boolean;
  placeholder?: string;
  value?: string;
  onChange: (event: ChangeEvent) => void;
  onSelect: (place: google.maps.places.AutocompletePrediction) => void;
  error?: boolean;
  fullWidth?: boolean;
  status: string;
  data: google.maps.places.AutocompletePrediction[];
};

const PlacesAutocomplete = ({
  disabled,
  placeholder,
  value,
  onChange,
  onSelect,
  fullWidth,
  status,
  data,
}: PlacesAutocompleteProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLElement>(null); // Create a ref for the input element

  const handleClose = () => {
    setAnchorEl(null);
  };


  useEffect(() => {
    if (status === "OK") {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [status]);

  return (
    <>
      <InputWithIcon
        id={"places-autocomplete"}
        icon={<SearchIcon fontSize="small" color="primary" />}
        placeholder={placeholder ? placeholder : ""}
        condensed={true}
        value={value}
        onChangeHandler={onChange}
        disabled={disabled}
        fullWidth={fullWidth}
        inputProps={{
          ref: inputRef,
        }}
      />

      <Popper
        placement={"top"}
        open={open}
        anchorEl={inputRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{ zIndex: 2, p: 0.5 }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper sx={{ boxShadow: theme.customShadows.z1 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <List
                  component="nav"
                  sx={{
                    p: 0,
                    width: "100%",
                    bgcolor: theme.palette.background.paper,
                    borderRadius: 0.5
                  }}
                >
                  {data.map((el) => (
                    <div key={"suggestion-" + el.place_id}>
                      <ListItemButton
                        onClick={() => onSelect(el)}
                        sx={{
                          // Change bgcolor when hovering
                          '&:hover': {
                            bgcolor: 'primary.lighter', // Replace 'hoverColor' with your desired color
                          },
                          // Apply styles when `selected` is true
                          '&.Mui-selected': {
                            bgcolor: 'primary.lighter', // Replace 'selectedColor' with your desired color when selected
                            // If you also want to change the hover color when selected
                            '&:hover': {
                              bgcolor: 'primary.lighter', // Replace 'selectedHoverColor' with your desired color on hover when selected
                            },
                          },
                        }}
                      >
                        <ListItemText primary={el.description} />
                      </ListItemButton>
                      <Divider />
                    </div>
                  ))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default PlacesAutocomplete;
