
// Google Maps
export let googlePlacesAutocompleteLoaded = false;

export function loadGooglePlacesAutocompleteScript(apiKey: string, callback: () => void) {
   if (googlePlacesAutocompleteLoaded) {
      callback();
      return;
   }

   const script = document.createElement('script');
   // script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&loading=async&libraries=places&callback=${callback.name}`;
   script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&loading=async&libraries=places`;
   script.async = true;
   script.defer = true;
   document.head.appendChild(script);

   script.onload = () => {
      googlePlacesAutocompleteLoaded = true;
      callback();
   };
}