// types
import { createSlice } from '@reduxjs/toolkit';
import { LayoutProps } from 'types/layout';

// initial state
const initialState: LayoutProps = {
  mapFeatureContextExpanded: false
};

// ==============================|| SLICE - MENU ||============================== //

const layout = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    expandMapFeatureContext(state, action) {
      state.mapFeatureContextExpanded = action.payload.mapFeatureContextExpanded;
    },
  }
});

export default layout.reducer;

export const { expandMapFeatureContext } = layout.actions;
