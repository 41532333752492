// third-party
import { FormattedMessage } from 'react-intl';

// mui icons
import FolderIcon from '@mui/icons-material/FolderRounded';
import AssignmentIcon from '@mui/icons-material/AssignmentRounded';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeekRounded';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  FolderIcon,
  AssignmentIcon,
  CalendarViewWeekIcon
};

// ==============================|| MENU ITEMS - DATA ||============================== //

const data: NavItemType = {
  id: 'data',
  title: <FormattedMessage id="data" />,
  type: 'group',
  children: [
    {
      id: 'lists-page',
      title: <FormattedMessage id="lists-page" />,
      type: 'item',
      url: '/lists-page',
      icon: icons.CalendarViewWeekIcon
    },
    {
      id: 'projects-page',
      title: <FormattedMessage id="projects" />,
      type: 'item',
      url: '/projects-page',
      icon: icons.AssignmentIcon,
    },
    {
      id: 'files-page',
      title: <FormattedMessage id="files-page" />,
      type: 'item',
      url: '/files-page',
      icon: icons.FolderIcon,
      // chip: {
      //   label: 'Q1-24',
      //   color: 'primary',
      //   size: 'small'
      // },
      // disabled: true,
    }
  ]
};

export default data;
