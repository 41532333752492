// Project imports
import MultipleTabsCheck from "./components/multipleTabsCheck";
import SoftwareVersionCheck from "./components/softwareVersionCheck";

const EnvironmentMonitor = () => {

  return (
    <>
      <SoftwareVersionCheck />
      <MultipleTabsCheck />
    </>
  );
};

export default EnvironmentMonitor;
