// image
import skovkortetLogo from 'assets/images/skovkortet-logo.png';
import forslerLogo from 'assets/images/forsler-logo.png';
import { TCountryCode } from "countries-list";

// ==============================|| LOGO PNG ||============================== //

const LogoMain = ({ reverse, countryCode }: { reverse?: boolean, countryCode?: TCountryCode }) => {
  /*
  * Originale size were: width="118" height="35" viewBox="0 0 118 35"
  */

  if (countryCode && countryCode !== "DK") {
    return <img src={forslerLogo} alt="Forsler logo" height="35px" />
  }

  return (
    <img src={skovkortetLogo} alt="Skovkortet.dk logo" height="35px" />
  );
};

export default LogoMain;
