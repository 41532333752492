import { useEffect, useRef, useState, useMemo } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { AppBar, Box, ClickAwayListener, Paper, Popper, Toolbar } from "@mui/material";

// material ui icons
import MoreVertIcon from "@mui/icons-material/MoreVertRounded";

// project import
import useConfig from "hooks/useConfig";
import Localization from "./Localization";
//import Search from './Search';
import Profile from "./Profile";
import IconButton from "components/@extended/IconButton";
import Transitions from "components/@extended/Transitions";

// ==============================|| HEADER CONTENT - MOBILE ||============================== //

type MobileSectionProps = {
  showProfile: boolean;
};
const MobileSection = ({ showProfile }: MobileSectionProps) => {
  // --- Hooks --- //
  const { i18n } = useConfig();
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<any>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const localization = useMemo(() => <Localization />, [i18n]);

  const iconBackColorOpen = theme.palette.mode === "dark" ? "grey.200" : "primary.lighter";
  const iconBackColor = theme.palette.mode === "dark" ? "background.default" : "grey.100";

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          sx={{ color: "text.primary", bgcolor: open ? iconBackColorOpen : iconBackColor }}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          color="secondary"
          variant="light"
        >
          <MoreVertIcon fontSize="small" />
        </IconButton>
      </Box>

      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{
          width: "100%",
        }}
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper sx={{ boxShadow: theme.customShadows.z1 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <AppBar color="inherit">
                  <Toolbar>
                    {/* <Search /> */}
                    {localization}

                    {showProfile && (
                      <Box
                        sx={{ bgcolor: "#D6D6D6", width: "1px", height: "36px", ml: 1, mr: 0.2 }}
                      />
                    )}

                    {showProfile && <Profile />}
                  </Toolbar>
                </AppBar>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default MobileSection;
