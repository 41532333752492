import { SyntheticEvent } from "react";

// material ui
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Chip, createTheme, ThemeProvider } from "@mui/material";

type AutocompleteProps = {
    title: string,
    label: string,
    value?: any[],
    fixedOptions?: any[],
    data: any[],
    showLabel: boolean,
    noOptionsText: string,
    changeHandler: (e: SyntheticEvent<Element, Event>, v: any) => void
    multiple?: boolean
}

const palette = {
    primary: {
        main: "#d9d9d9"
    },
    secondary: {
        main: "#1E5128",
        dark: "#2E5F24",
        lighter: "#D8E9A8"
    }
};

const theme = createTheme({
    palette,
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                listbox: {
                    "& .MuiAutocomplete-option": {
                        "&.Mui-focused": {
                            backgroundColor: palette.primary.main
                        }
                    }
                },
                inputRoot: {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: palette.primary.main
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: palette.primary.main
                    }
                }
            }
        }
    }
});

const AutoCompleteTags = ({ title, label, value, fixedOptions, data, showLabel, noOptionsText, changeHandler, multiple }: AutocompleteProps) => {
    let fixedOptionsIn = fixedOptions ? fixedOptions : [];

    return (
        <>
            {!showLabel && <Typography variant="h5" sx={{ mb: 1 }}>
                {title}
            </Typography>}

            <ThemeProvider theme={theme}>
                <Autocomplete
                    multiple={multiple}
                    limitTags={2}
                    id="multiple-limit-tags"
                    value={value}
                    options={data}
                    noOptionsText={noOptionsText}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    defaultValue={data}
                    onChange={(e, v) => changeHandler(e, v)}
                    filterSelectedOptions
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip label={option.label} {...getTagProps({ index })} disabled={fixedOptionsIn.indexOf(option) !== -1} />
                        ))
                    }
                    renderInput={(params) => <TextField {...params} placeholder={label} label={showLabel ? title : null} sx={{ '& .MuiFormLabel-root': { fontSize: 14.5 } }} />}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            p: 0.4,
                            height: multiple ? null : 41
                        },
                        '& .MuiFormLabel-root': {
                            marginTop: "-5px",
                        },
                        '& .MuiInputLabel-shrink': {
                            marginTop: "0px",
                        },
                        '& .MuiAutocomplete-tag': {
                            bgcolor: 'secondary.lighter',
                            borderRadius: 1,
                            height: 28,
                            pl: 1.5,
                            pr: 1.5,
                            lineHeight: '28px',
                            '& .MuiChip-label': {
                                paddingLeft: 0,
                                paddingRight: 0
                            },
                            '& .MuiSvgIcon-root': {
                                color: 'secondary.main',
                                ml: 1,
                                mr: -0.75,
                                '&:hover': {
                                    color: 'secondary.dark',
                                }
                            }
                        },
                        '& .MuiInputBase-input': {
                            fontSize: 15,
                            mb: '-2px'
                        }
                    }}
                />
            </ThemeProvider>
        </>
    )
}

export default AutoCompleteTags;