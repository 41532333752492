// material-ui
import { Typography, DialogContent, Divider } from '@mui/material';
import { useTranslation } from 'utils/locales/utilityFunctions';

// ==============================|| WELCOME ||============================== //

export default function Welcome() {
   const { translate } = useTranslation();
   return (
      <>
         <DialogContent>
            <Typography variant="h5" sx={{ mb: 2 }}>
               {translate("onboarding-welcome-title")}
            </Typography>

            <Typography variant="h6" sx={{ mb: 3 }}>
               {translate("onboarding-welcome-text")}
            </Typography>
         </DialogContent>

         <Divider />
      </>
   );
}
