import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Toolbar, Container } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import Footer from './Footer';
import useConfig from 'hooks/useConfig';

// types
import { RootStateProps } from 'types/root';
import { openDrawer } from 'store/reducers/menu';
import useAppFunctionalityConfig from 'hooks/useAppFunctionalityConfig';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    // --- Hooks --- //
    const theme = useTheme();
    const location = useLocation();
    const config = useAppFunctionalityConfig().config.layout;

    const matchDownXL = useMediaQuery(theme.breakpoints.down('xl'));

    const { miniDrawer } = useConfig();
    const dispatch = useDispatch();

    const menu = useSelector((state: RootStateProps) => state.menu);
    const { drawerOpen } = menu;

    // --- State & Refs --- //
    // drawer toggler
    const [open, setOpen] = useState((!miniDrawer || drawerOpen) && config.sidebar === 'enabled');
    
    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };

    // set media wise responsive drawer
    useEffect(() => {
        // Config behavior
        if (config.sidebar !== 'enabled') {
            setOpen(false);
            dispatch(openDrawer({ drawerOpen: false }));
            return;
        }
        // Else normal behaviour
        if (!miniDrawer) {
            setOpen(!matchDownXL);
            dispatch(openDrawer({ drawerOpen: !matchDownXL }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownXL, config]);

    // Set reducer mismatch drawer
    useEffect(() => {
        if (drawerOpen !== open) {
            setOpen(drawerOpen);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerOpen]);

    let mapPage = false;
    if (location.pathname.includes("map-page")) {
        mapPage = true;
    }

    let listPage = false;
    if (location.pathname.includes("lists-page")) {
        listPage = true;
    }

    let p = { xs: 2, sm: 3 };
    if (mapPage) {
        p = 0 as any;
    }

    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <Header open={open} drawerDisabled={config.sidebar !== "enabled"} handleDrawerToggle={handleDrawerToggle} />
            <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
            <Box component="main" sx={{ width: 'calc(100% - 260px)', flexGrow: 1, p: p }}>
                <Toolbar />

                {!mapPage && (
                    <Container
                        maxWidth={listPage ? false : "xl"}
                        sx={{ px: { xs: 0, sm: 2 }, position: 'relative', minHeight: 'calc(100vh - 110px)', display: 'flex', flexDirection: 'column' }}
                    >
                        <Outlet />
                        <Footer />
                    </Container>)}

                {/* Toolbar is specified to have minHeight: 60px in themes */}
                {mapPage && <Box sx={{}}>
                    <Outlet />
                </Box>}
            </Box>
        </Box>
    );
};

export default MainLayout;
