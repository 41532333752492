import { useContext } from 'react';
import MapDataContext from 'contexts/MapDataContext';
import { MapDataContextType } from 'types/mapData';

// ==============================|| ESTATES - HOOKS  ||============================== //

const useMapData = () => {
    const context = useContext(MapDataContext) as MapDataContextType; // To ensure data and functions are not unknown when init is null;
    if (context === undefined) throw new Error("useForestDataContext was used outside of its Provider");
    return context;
}

export default useMapData;