import React, { forwardRef } from 'react';
// material-ui
import { OutlinedInput } from '@mui/material';

// project import
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

type InputWithIconProps = {
   icon: ReactJSXElement,
   placeholder: string,
   condensed?: boolean,
   value?: string,
   fullWidth?: boolean,
   disabled?: boolean,
   id?: string,
   inputProps?: any,
   onChangeHandler?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void,
}

const InputWithIcon = forwardRef<HTMLInputElement, InputWithIconProps>(
   ({ icon, placeholder, condensed, value, fullWidth, disabled, id, inputProps, onChangeHandler }, ref) => {
       return (
           <OutlinedInput
               id={id ? id : `start-adornment-email` + placeholder}
               placeholder={placeholder}
               inputRef={ref}
               value={value}
               startAdornment={icon}
               onChange={onChangeHandler}
               color="secondary"
               fullWidth={fullWidth}
               disabled={disabled}
               inputProps={inputProps}
               sx={{
                   bgcolor: 'white',
                   '& .MuiInputBase-input': {
                       fontSize: 14.5,
                       height: condensed ? 15 : null,
                   }
               }}
           />
       );
   }
);

export default InputWithIcon;
