import { useEffect, useRef, useState } from "react";

// MUI
import { Box } from "@mui/material";

// Project import
import useEstatesAndMaps from "hooks/useEstatesAndMaps";
import AutoCompleteGrouped from "components/input/autoCompleteGrouped";
import { useTranslation } from "utils/locales/utilityFunctions";
import getDataOptions from "./getDataOptions";
import useAuth from "hooks/useAuth";
import { RoleTypes } from "types/auth";
import CircularSpinner from "components/display/circularSpinner";
import useMapData from "hooks/useMapData";
import useAppFunctionalityConfig from "hooks/useAppFunctionalityConfig";
import { map } from "lodash";

interface EstatesAndMapSelecterProps {
  parent?: string;
}

const EstatesAndMapSelecter = ({ parent }: EstatesAndMapSelecterProps) => {
  // --- Hooks --- //
  const { translate } = useTranslation();
  const { user } = useAuth();
  const config = useAppFunctionalityConfig().config.layout;

  const {
    estates,
    orgEstates,
    maps,
    projects,
    selectedEstateId,
    selectedMapId,
    selectedProjectId,
    hasZoomed,
    appUpgradeNotifications,
    estateAndMapKeysNotAllowed,
    setSelectedEstateId,
    setSelectedMapId,
    setSelectedProjectId,
    setHasZoomed,
  } = useEstatesAndMaps();
  const { setSelectedActivityId, setSelectedFeature } = useMapData();

  // Roles
  const ROLE_OPTIONS = [
    { id: "viewer" as RoleTypes, status: "enabled", label: translate("role-view") },
    { id: "commentator" as RoleTypes, status: "enabled", label: translate("role-comment") },
    { id: "editor" as RoleTypes, status: "enabled", label: translate("role-edit") },
    { id: "admin" as RoleTypes, status: "enabled", label: translate("role-admin") },
  ];

  // --- States and references --- //
  const setSelectFirstTimeRef = useRef(false);
  const selectedItemsRef = useRef<any[]>([]);
  const [options, setOptions] = useState<any[] | null>(null);
  const [groupIdToName, setGroupIdToName] = useState<{ [key: string]: string } | undefined>(
    undefined
  );
  const [value, setValue] = useState<any>([]);
  const [upgradeNotification, setUpgradeNotification] = useState<
    { variant: "info" | "warning" | "error"; text: string; dismissable: boolean } | undefined
  >(undefined);

  // --- Effects --- //
  const overviewText = translate("overview");
  useEffect(() => {
    // Check if there are any keys that are not allowed according to limitations
    const mapKeysNotAllowed = estateAndMapKeysNotAllowed
      ? estateAndMapKeysNotAllowed.maps
      : undefined;
    const estateKeysNotAllowed = estateAndMapKeysNotAllowed
      ? estateAndMapKeysNotAllowed.estates
      : undefined;
    const newOptions = getDataOptions({
      user,
      estates,
      orgEstates,
      maps,
      projects,
      overviewText: overviewText,
      allRoleOptions: ROLE_OPTIONS,
      mapKeysNotAllowed,
      estateKeysNotAllowed,
    });
    setOptions(newOptions.list);
    setGroupIdToName(newOptions.estateIdsAndNames);
    if (!setSelectFirstTimeRef.current) {
      if (Object.keys(estates).length === 1 && Object.keys(maps).length === 1) {
        setSelectedEstateId(Object.keys(estates)[0]);
        setSelectedMapId(Object.keys(maps)[0]);
        setHasZoomed(null);
        setSelectFirstTimeRef.current = true;
      }
    }
  }, [estates, maps, projects, overviewText, appUpgradeNotifications]);

  // Set upgrade notification
  useEffect(() => {
    if (appUpgradeNotifications) {
      const {
        mapLimitExceeded,
        estateLimitExceeded,
        estateAndMapLimitExceeded,
      } = appUpgradeNotifications;
      if (estateAndMapLimitExceeded) {
        setUpgradeNotification({
          variant: "warning",
          text: translate("upgrade-notification-estate-and-map-limit-reached-auto-complete"),
          dismissable: false,
        });
      } else if (estateLimitExceeded) {
        setUpgradeNotification({
          variant: "warning",
          text: translate("upgrade-notification-estate-limit-reached-auto-complete"),
          dismissable: false,
        });
      } else if (mapLimitExceeded) {
        setUpgradeNotification({
          variant: "warning",
          text: translate("upgrade-notification-map-limit-reached-auto-complete"),
          dismissable: false,
        });
      } else {
        setUpgradeNotification(undefined);
      }
    }
  }, [appUpgradeNotifications, overviewText]);

  useEffect(() => {
    if (options) {
      if (!selectedEstateId && !selectedMapId && !selectedProjectId) {
        setValue([]);
      } else if (selectedEstateId && !selectedMapId) {
        const val = options.filter(
          (el) => el.estateId === selectedEstateId && !el.mapId && !el.projectId
        );
        setValue(val);
        selectedItemsRef.current = val;
      }
      if (selectedMapId && selectedProjectId) {
        const val = options.filter(
          (el) => el.mapId === selectedMapId || el.projectId === selectedProjectId
        );
        setValue(val);
        selectedItemsRef.current = val;
      } else if (selectedMapId) {
        const val = options.filter((el) => el.mapId === selectedMapId);
        setValue(val);
        selectedItemsRef.current = val;
      } else if (selectedProjectId) {
        const val = options.filter((el) => el.projectId === selectedProjectId);
        setValue(val);
        selectedItemsRef.current = val;
      }
    }
  }, [selectedEstateId, selectedMapId, selectedProjectId, options]);

  // --- Event handlers --- //
  const autoselectChangeHandler = (e: any, v: any) => {
    // Check for values in the select array
    if (v.length === 0) {
      setSelectedEstateId(null);
      setSelectedMapId(null);
      setSelectedProjectId(null);
      setValue([]);
      selectedItemsRef.current = [];
      setHasZoomed(null);
      return;
    } else if (v.length === 1 && selectedItemsRef.current.length === 2) {
      // Handle removed map or project
      if (v[0].dataElType && v[0].dataElType === "map") {
        if (hasZoomed !== "map") setHasZoomed(null);
        setSelectedProjectId(null);
      } else if (v[0].dataElType && v[0].dataElType === "project") {
        if (hasZoomed !== "project") setHasZoomed(null);
        setSelectedMapId(null);
      }
      setSelectedFeature(null);
      return;
    }
    // Set selected estate and map
    const lastItem = v.slice(-1)[0];
    const { estateId, mapId, projectId } = lastItem;
    if (estateId && (estateId !== selectedEstateId || (!mapId && !projectId))) {
      setSelectedEstateId(estateId);
      setSelectedMapId(null);
      setSelectedProjectId(null);
      setHasZoomed(null);
    }
    // if (!mapId) setSelectedMapId(null);
    // if (!projectId) setSelectedProjectId(null);
    if (mapId && mapId !== selectedMapId) {
      setSelectedMapId(mapId);
      setHasZoomed(null);
    }
    if (projectId && projectId !== selectedProjectId) {
      setSelectedProjectId(projectId);
      setSelectedActivityId(null);
      setHasZoomed(null);
    }
    selectedItemsRef.current = v;
  };

  // Spinner untill data is available
  if (!options) return <CircularSpinner loading={true} variant={"iconButton"} />;

  return (
    <Box
      sx={{
        width: {
          xs: "100%",
          sm: parent === "map" ? "100%" : 250,
          md: parent === "map" ? 500 : 430,
        },
      }}
    >
      <AutoCompleteGrouped
        title="title"
        label={translate("choose-estate-project-map")}
        showTitle={false}
        showLabel={false}
        disabled={config.selectMapOrProject !== "enabled"}
        data={options}
        groupByField={"estateId"}
        groupByFieldToLabel={groupIdToName}
        renderOptionField={"mapName"}
        renderTagField={"tagField"}
        noOptionsText={translate("no-options")}
        changeHandler={autoselectChangeHandler}
        value={value}
        condensed={true}
        multiple={true}
        upgradeNotification={upgradeNotification}
      />
    </Box>
  );
};

export default EstatesAndMapSelecter;
