import ConfirmDialog from "components/dialogs/ConfirmDialog";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "utils/locales/utilityFunctions";

const MultipleTabsCheck = () => {
  // --- Hooks --- //
  const { translate } = useTranslation();
  // --- State and refs --- //
  const [openDialog, setOpenDialog] = useState(false); // State to show the alert dialog
  const channelRef = useRef<BroadcastChannel | null>(null); // Reference to the BroadcastChannel
  const alertedRef = useRef(false); // Track if an alert has already been shown

  useEffect(() => {
    // Initialize the BroadcastChannel
    const channel = new BroadcastChannel("app_tabs");
    channelRef.current = channel;

    // Listener for messages from other tabs
    const handleMessage = (event: MessageEvent) => {
      if (event.data === "newTabOpened") {
        // Show alert if another tab is open, and this tab hasn't shown the alert yet
        if (!alertedRef.current) {
          setOpenDialog(true);
          //   alert("Another tab is already open. Please use a single tab for the best experience.");
          alertedRef.current = true;
        }
      }

      if (event.data === "init") {
        // If this tab receives an 'init' message, it means another tab just opened
        // Notify the new tab of this tab's existence
        channel.postMessage("newTabOpened");
      }
    };

    // Add message event listener
    channel.addEventListener("message", handleMessage);

    // Notify other tabs that this tab has opened
    channel.postMessage("init");

    // Cleanup on component unmount
    return () => {
      channel.removeEventListener("message", handleMessage);
      channel.close(); // Close the channel when the component unmounts
    };
  }, []);

  // Render
  if (openDialog) {
    return (
      <ConfirmDialog
        open={true}
        maxWidth="xs"
        title={translate("multiple-tabs-title")}
        text={translate("multiple-tabs-text")}
        onConfirmText={translate("multiple-tabs-button-confirm-text")}
        onCancelText={translate("close")}
        //   onCancel={() => setOpenDialog(false)}
        onConfirm={() => setOpenDialog(false)}
      />
    );
  }

  return null;
};

export default MultipleTabsCheck;
