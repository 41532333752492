import { Link as RouterLink } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Stack, Link, Button } from "@mui/material";

// project import
import { useTranslation } from "utils/locales/utilityFunctions";
import useAuth from "hooks/useAuth";

export type UpgradeNotificationProps = {
  variant: "error" | "info" | "warning" | "success";
  text: string;
  dismissable: boolean;
  handleClose?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const UpgradeNotification = ({
  variant,
  text,
  dismissable,
  handleClose,
}: UpgradeNotificationProps) => {
  // --- Hooks --- //
  const theme = useTheme();
  const { translate } = useTranslation();
  const { userOrg } = useAuth();

    const inTrial = userOrg?.subscription?.type === "trial";

  // --- Functions --- //
  const getColorScheme = (variant: string) => {
    switch (variant) {
      case "info":
        return {
          lighter: theme.palette.info.lighter,
          main: theme.palette.info.main,
          darker: theme.palette.info.darker,
          highlight: translate("upgrade-notification-info"),
        };
      case "warning":
        return {
          lighter: theme.palette.warning.lighter,
          main: theme.palette.warning.main,
          darker: theme.palette.warning.darker,
          highlight: translate("upgrade-notification-warning"),
        };
      case "success":
        return {
          lighter: theme.palette.success.lighter,
          main: theme.palette.success.main,
          darker: theme.palette.success.darker,
          highlight: translate("upgrade-notification-success"),
        };
      case "error":
      default:
        return {
          lighter: theme.palette.error.lighter,
          main: theme.palette.error.main,
          darker: theme.palette.error.darker,
          highlight: translate("upgrade-notification-error"),
        };
    }
  };

  const notificationContext = getColorScheme(variant);
  const linkText = translate(
    inTrial ? "upgrade-notification-link-text-trial" : "upgrade-notification-link-text"
  );
  const buttonText = translate( inTrial ? "upgrade-notification-button-text-trial" : "upgrade-notification-button-text");

  // --- Render --- //
  return (
    <Box
      sx={{
        border: `1px solid ${notificationContext.main}`,
        bgcolor: notificationContext.lighter,
        p: 1.5,
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        maxWidth: {
          xs: "100%",
          sm: `calc(100vw - 244px)`,
          lg: `calc(100vw - 274px)`,
          xl: `calc(100vw - 474px)`,
        },
      }}
    >
      <Stack
        direction={{ sm: "row", xs: "column" }}
        spacing={2}
        alignItems={{ sm: "center", xs: "flex-start" }}
      >
        <Box style={{ flexGrow: 1 }}>
          <strong style={{ color: notificationContext.main }}>
            {notificationContext.highlight}
          </strong>{" "}
          {text}{" "}
          <Link
            component={RouterLink}
            to={"/account-page"}
            style={{ color: notificationContext.main, textDecoration: "underline" }}
          >
            {linkText}
          </Link>
          .
        </Box>

        {!dismissable && (
          <Button
            variant="contained"
            sx={{
              bgcolor: notificationContext.main,
              "&:hover": {
                bgcolor: notificationContext.darker,
              },
              whiteSpace: "nowrap",
              width: "auto",
              textTransform: "none",
              px: 3,
            }}
            component={RouterLink}
            to="/account-page"
          >
            {buttonText}
          </Button>
        )}

        {dismissable && (
          <Button
            variant="contained"
            sx={{
              bgcolor: notificationContext.main,
              "&:hover": {
                bgcolor: notificationContext.darker,
              },
              whiteSpace: "nowrap",
              width: "auto",
              textTransform: "none",
              px: 3,
            }}
            onClick={handleClose}
          >
            {translate("close")}
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default UpgradeNotification;
