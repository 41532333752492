// third-party
import { combineReducers } from 'redux';

// project import
import layout from './layout';
import menu from './menu';
import snackbar from './snackbar';
// import estatesAndMaps from './EstatesAndMaps';
// import mapData from './MapData';
// import files from './files';
// import appSettings from './appSettings';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
   layout,
   menu,
   snackbar,
   // estatesAndMaps,
   // mapData,
   // files,
   // appSettings,
});

export default reducers;
