


export default function checkCompanyNumberFormat(input: string, countryCode: string) {
   switch (countryCode.toLowerCase()) {
      case "da": return checkDK(input);
      default: throw new Error("invalid-country-code");
   }
}

// Country specific checks
function checkDK(input: string) {
   return validateInputFormat(input, 7);
}

// Support functions
export const validateInputFormat = (input: string, length: number): boolean => {
   const regex = /^[0-9]+$/;
   return regex.test(input) && input.length > length;
};