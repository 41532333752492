// third-party
import { FormattedMessage } from 'react-intl';

// mui icons
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomizeRounded';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  DashboardCustomizeIcon
};

// ==============================|| MENU ITEMS - HOME ||============================== //

const home: NavItemType = {
  id: 'home',
  title: <FormattedMessage id="home" />,
  type: 'group',
  children: [
    {
      id: 'home-page',
      title: <FormattedMessage id="home-page" />,
      type: 'item',
      url: '/home-page',
      icon: icons.DashboardCustomizeIcon,
      disabled: false
    }
  ]
};

export default home;
