import { Link } from "react-router-dom";
import { To } from "history";
import { TCountryCode } from "countries-list";

// material-ui
import { ButtonBase } from "@mui/material";
import { SxProps } from "@mui/system";

// project import
import Logo from "./LogoMain";
import LogoIcon from "./LogoIcon";
import config from "config/config";
import useAuth from "hooks/useAuth";

// ==============================|| MAIN LOGO ||============================== //

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: To;
}

const LogoSection = ({ reverse, isIcon, sx, to }: Props) => {
  const { user, userOrg } = useAuth();
  // Find the country code based on the full url path and if it includes "skovkortet"
  const path = window.location.href.toLowerCase();
  let langAbbr = "en";
  if (path.includes("skovkortet")) {
    langAbbr = "da";
  }
  const langAbbrToCountryCode = langAbbr !== "da" ? "EN" : "DK";
  // Change the country code based on the user's organization or user's country code
  const countryCode = (userOrg?.countryCode || user?.countryCode || langAbbrToCountryCode) as TCountryCode;

  return (
    <ButtonBase
      disableRipple
      component={Link}
      to={!to ? config.defaultPath : to}
      sx={sx}
    >
      {isIcon ? (
        <LogoIcon />
      ) : (
        <Logo reverse={reverse} countryCode={countryCode} />
      )}
    </ButtonBase>
  );
};

export default LogoSection;
