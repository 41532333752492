const productFruitsTourUrls: { [key: string]: string } = {
  welcome: "/home-page/PF/welcome",
  theFirstRegistrations: "/home-page/PF/the-first-registrations",
  mapsAndOverlays: "/home-page/PF/maps-and-overlays",
  inventoryDataAndFilters: "/home-page/PF/inventory-data-and-filters",
  forecastAndDistributionPlots: "/home-page/PF/forecast-and-distribution-plots",
  mobileAndTablet: "/home-page/PF/mobile-and-tablet",
  postItNotes: "/home-page/PF/post-it-notes",
  gpsTracking: "/home-page/PF/gps-tracking",
  uploadFiles: "/home-page/PF/upload-files",
  categories: "/home-page/PF/categories",
  shareData: "/home-page/PF/share-data",
  tasksAndInventoryData: "/home-page/PF/tasks-and-inventory-data",
  tasksAndProjectBoard: "/home-page/PF/tasks-and-project-board",
  communication: "/home-page/PF/communication",
};

export const getProductFruitsTourUrl = (key: string): string => {
  let returnValue = "/";
  if (productFruitsTourUrls[key]) {
    returnValue = productFruitsTourUrls[key];
  }
  return returnValue;
};
