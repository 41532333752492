import { forwardRef, useEffect, ForwardRefExoticComponent, RefAttributes } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

// project import
import { activeItem } from "store/reducers/menu";
import useAppFunctionalityConfig from "hooks/useAppFunctionalityConfig";
import { PAGES } from "config/config-general";
import config from "config/config";

// types
import { LinkTarget, NavItemType } from "types/menu";
import { RootStateProps } from "types/root";
import { AppLayoutConfigProps } from "types/appFunctionality";
import useAuth from "hooks/useAuth";
import { useTranslation } from "utils/locales/utilityFunctions";
import { getCustomDefaultPath } from "utils/textModification";

// ==============================|| NAVIGATION - LIST ITEM ||============================== //
interface Props {
  itemIn: NavItemType;
  level: number;
}

const NavItem = ({ itemIn, level }: Props) => {
  const theme = useTheme();
  const { userOrg } = useAuth();
  const { translate } = useTranslation();
  const { config: AppConfig } = useAppFunctionalityConfig();
  const dispatch = useDispatch();
  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen, openItem } = menu;
  let item = { ...itemIn };

  if (item.id === "account-page" && userOrg?.subscription?.type === "trial") {
    item.chip = {
      label: translate("upgrade-hint"),
      color: "success",
      size: "small",
    };
  }

  let itemTarget: LinkTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  let listItemProps: {
    component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
    href?: string;
    target?: LinkTarget;
  } = {
    component: forwardRef((props, ref) => <Link {...props} to={item.url!} target={itemTarget} />),
  };
  if (item?.external) {
    listItemProps = { component: "a", href: item.url, target: itemTarget };
  }

  const Icon = item.icon!;
  const itemIcon = item.icon ? (
    <Icon style={{ fontSize: drawerOpen ? "1rem" : "1.25rem" }} />
  ) : (
    false
  );

  const isSelected = openItem.findIndex((id) => id === item.id) > -1;

  const { pathname } = useLocation();

  // active menu item on page load
  useEffect(() => {
    const customDefaultPath = getCustomDefaultPath({
      url: pathname,
      defaultPath: config.defaultPath,
      defaultPaths: PAGES,
    });

    if (customDefaultPath === item.url) {
      dispatch(activeItem({ openItem: [item.id] }));
    }
    // eslint-disable-next-line
  }, [pathname]);

  const textColor = theme.palette.mode === "dark" ? "grey.400" : "text.primary";
  const iconSelectedColor =
    theme.palette.mode === "dark" && drawerOpen ? "text.primary" : "primary.main";

  // Check for hidden in app config
  if (checkNavItemHiddenState(item, AppConfig.layout)) return null;
  // Proceed with rendering
  return (
    <ListItemButton
      className={"pf-nav-item-" + item.id}
      {...listItemProps}
      disabled={checkNavItemDisabledState(item, AppConfig.layout)}
      selected={isSelected}
      sx={{
        zIndex: 1201,
        pl: drawerOpen ? `${level * 28}px` : 1.5,
        py: !drawerOpen && level === 1 ? 1.25 : 1,
        ...(drawerOpen && {
          "&:hover": {
            bgcolor: theme.palette.mode === "dark" ? "divider" : "primary.lighter",
          },
          "&.Mui-selected": {
            bgcolor: theme.palette.mode === "dark" ? "divider" : "primary.lighter",
            borderRight: `2px solid ${theme.palette.primary.main}`,
            color: iconSelectedColor,
            "&:hover": {
              color: iconSelectedColor,
              bgcolor: theme.palette.mode === "dark" ? "divider" : "primary.lighter",
            },
          },
        }),
        ...(!drawerOpen && {
          "&:hover": {
            bgcolor: "transparent",
          },
          "&.Mui-selected": {
            "&:hover": {
              bgcolor: "transparent",
            },
            bgcolor: "transparent",
          },
        }),
      }}
    >
      {itemIcon && (
        <ListItemIcon
          sx={{
            minWidth: 28,
            color: isSelected ? iconSelectedColor : textColor,
            ...(!drawerOpen && {
              borderRadius: 1.5,
              width: 36,
              height: 36,
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                bgcolor: theme.palette.mode === "dark" ? "secondary.light" : "secondary.lighter",
              },
            }),
            ...(!drawerOpen &&
              isSelected && {
                bgcolor: theme.palette.mode === "dark" ? "primary.900" : "primary.lighter",
                "&:hover": {
                  bgcolor: theme.palette.mode === "dark" ? "primary.darker" : "primary.lighter",
                },
              }),
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && (
        <ListItemText
          primary={
            <Typography variant="h6" sx={{ color: isSelected ? iconSelectedColor : textColor }}>
              {item.title}
            </Typography>
          }
        />
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

export default NavItem;

// --- Helper functions --- //
function checkNavItemDisabledState(item: NavItemType, config: AppLayoutConfigProps) {
  if (item.disabled) return true;
  // if (item.id === "home-page") return false;
  // if (item.id === "map-page" && config.mapPage === "disabled") return true;
  // if (item.id === "lists-page" && config.listsPage === "disabled") return true;
  // if (item.id === "projects-page" && config.projectsPage === "disabled") return true;
  // if (item.id === "files-page" && config.filesPage === "disabled") return true;
  // if (item.id === "estates-page" && config.estatesPage === "disabled") return true;
  // if (item.id === "team-and-contacts-page" && config.teamAndContactsPage === "disabled") return true;
  // if (item.id === "profile-page" && config.profilePage === "disabled") return true;
  // if (item.id === "account-page" && config.accountPage === "disabled") return true;
  return false;
}

function checkNavItemHiddenState(item: NavItemType, config: AppLayoutConfigProps) {
  if (item.id === "home-page" && config.homePage === "hidden") return true;
  if (item.id === "map-page" && config.mapPage === "hidden") return true;
  if (item.id === "lists-page" && config.listsPage === "hidden") return true;
  if (item.id === "projects-page" && config.projectsPage === "hidden") return true;
  if (item.id === "files-page" && config.filesPage === "hidden") return true;
  if (item.id === "estates-page" && config.estatesPage === "hidden") return true;
  if (item.id === "team-and-contacts-page" && config.teamAndContactsPage === "hidden") return true;
  if (item.id === "profile-page" && config.profilePage === "hidden") return true;
  if (item.id === "account-page" && config.accountPage === "hidden") return true;
  return false;
}
