// material-ui
import {
   InputLabel,
   Stack,
   Typography,
   TextField,
   DialogContent,
   DialogActions,
   Divider,
   Box
} from '@mui/material';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';

// project imports
import Button from 'components/@extended/LoadingButton';
import { useTranslation } from 'utils/locales/utilityFunctions';

// ==============================|| HOW DID YOU LEARN ABOUT US ||============================== //

export type LearnAboutUsAnswer = {
   answer?: string;
};

interface LearnAboutUsProps {
   learnAboutUsAnswer: LearnAboutUsAnswer;
   disableBackButton?: boolean;
   setLearnAboutUsAnswer: (d: LearnAboutUsAnswer) => void;
   handleNext: () => void;
   handleBack: () => void;
   setErrorIndex: (i: number | null) => void;
}

const LearnAboutUs = ({ learnAboutUsAnswer, disableBackButton, setLearnAboutUsAnswer, handleNext, handleBack, setErrorIndex }: LearnAboutUsProps) => {
   // --- Hooks --- //
   const { translate } = useTranslation();

   const validationSchema = yup.object({
      answer: yup.string().required(translate("onboarding-error-required"))
   });

   const formik = useFormik({
      initialValues: {
         answer: learnAboutUsAnswer.answer
      },
      validationSchema,
      onSubmit: (values) => {
         setLearnAboutUsAnswer({
            answer: values.answer
         });
         handleNext();
      }
   });

   return (
      <>
         <DialogContent>
            <Typography variant="h5" sx={{ mb: 2 }}>
               {translate("onboarding-learn-about-us-title")}
            </Typography>

            <Typography variant="h6" sx={{ mb: 3 }}>
               {translate("onboarding-learn-about-us-text")}
            </Typography>

            <Box sx={{ mb: 3 }}>
               <form onSubmit={formik.handleSubmit} id="validation-forms">
                  <Stack spacing={0.5}>
                     <InputLabel>{translate("onboarding-learn-about-us-input-label")}</InputLabel>

                     <TextField
                        id="answer"
                        name="answer"
                        placeholder={translate("onboarding-learn-about-us-placeholder")}
                        value={formik.values.answer}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.answer && Boolean(formik.errors.answer)}
                        helperText={formik.touched.answer && formik.errors.answer}
                        fullWidth
                        autoComplete="hear-answer"
                        color="secondary"
                     />
                  </Stack>
               </form>
            </Box>
         </DialogContent>

         <Divider />

         <DialogActions>
            {/* ACTION */}
            <Stack direction="row" spacing={1} justifyContent='space-between' sx={{ width: 1, pl: 1.5, pr: 1.5, py: 0.75 }}>
               <Button color="secondary" disabled={disableBackButton} onClick={handleBack}>
                  {translate("back")}
               </Button>

               <Button
                  variant="contained"
                  type="button"
                  disabled={!formik.isValid || formik.isSubmitting || (!formik.dirty && formik.values.answer === "")}
                  loading={formik.isSubmitting}
                  onClick={() => {
                     setErrorIndex(1);
                     formik.handleSubmit();
                  }}
                  sx={{
                     bgcolor: 'primary.light',
                     '&.Mui-disabled': {
                        bgcolor: 'secondary.lighter',
                        color: 'secondary.main'
                     }
                  }}
               >
                  {translate("next")}
               </Button>
            </Stack>
         </DialogActions>
      </>
   );
};

export default LearnAboutUs;
