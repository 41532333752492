// third-party
import { FormattedMessage } from 'react-intl';

// mui icons
import MapIcon from '@mui/icons-material/MapRounded';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  MapIcon,
};

// ==============================|| MENU ITEMS - MAP ||============================== //

const map: NavItemType = {
  id: 'map',
  title: <FormattedMessage id="map" />,
  type: 'group',
  children: [
    {
      id: 'map-page',
      title: <FormattedMessage id="map-page" />,
      type: 'item',
      url: '/map-page',
      icon: icons.MapIcon
    }
  ]
};

export default map;
