// material ui
import { Box, CircularProgress } from "@mui/material";

type CircularSpinnerProps = {
    loading: boolean,
    variant: string,
}

const CircularSpinner = ({ loading, variant }: CircularSpinnerProps) => {
    if (!loading) return null

    let size = 40 // default
    if (variant === "medium") {
        size = 26
    }
    if (variant === "iconButton") {
        size = 20
    }
    if (variant === "small") {
        size = 12
    }

    return (
        <CircularProgress size={size} />
    )
}

export default CircularSpinner;
