// action - state management
import {
   ADDED_FILE_SUCCESS,
   MODIFIED_FILE_SUCCESS,
   REMOVED_FILE_SUCCESS,
   UPLOAD_FILE_SUCCESS,
   DELETE_FILE_SUCCESS,
   FILES_RESET_STATE,
   FILES_API_CALL_START,
   FILES_API_CALL_FAIL,
   DOWNLOAD_FILE_SUCCESS,
} from "./actions";

// Utility
import { FilesActionProps, FilesProps } from "types/files";
import { updateObject } from "utils/updateObject";

// Initial state
const initialState: FilesProps = {
   loading: false,
   error: null,
   orgs: {},
   estates: {},
   maps: {},
   projects: {},
   activities: {},
}

const files = (state = initialState, action: FilesActionProps) => {

   const uplodFileSuccess = (state: FilesProps, action: FilesActionProps) => {
      return updateObject(state, {
         loading: false,
         error: null,
      })
   }

   const downloadFileSuccess = (state: FilesProps, action: FilesActionProps) => {
      return updateObject(state, {
         loading: false,
         error: null,
      })
   }

   const deleteFileSuccess = (state: FilesProps, action: FilesActionProps) => {
      return updateObject(state, {
         loading: false,
         error: null,
      })
   }

   const addedFileSuccess = (state: FilesProps, action: FilesActionProps) => {
      const { rootFolder, rootId } = action;
      const { payload } = action;
      const id = payload.id;
      if (!rootFolder || !rootId) return state;
      // Change state object based on rootFolder
      let updatedFileState = { ...state[rootFolder] };
      // Add file to state
      updatedFileState[rootId] = { ...updatedFileState[rootId], [id]: payload };
      // Return updated state
      return updateObject(state, {
         loading: false,
         error: null,
         [rootFolder]: updatedFileState,
      })

   }

   const modifiedFileSuccess = (state: FilesProps, action: FilesActionProps) => {
      const { rootFolder, rootId } = action;
      const { payload } = action;
      const id = payload.id;
      if (!rootFolder || !rootId) return state;
      // Change state object based on rootFolder
      let updatedFileState = { ...state[rootFolder] };
      // Replace file in state if it exist, else add it
      if (updatedFileState[rootId] && updatedFileState[rootId][id]) {
         updatedFileState[rootId][id] = payload;
      } else {
         // File not found add it (can be caused by private or internal category change on linked feature)
         updatedFileState[rootId] = { ...updatedFileState[rootId], [id]: payload };
      }
      // Return updated state
      return updateObject(state, {
         loading: false,
         error: null,
         [rootFolder]: updatedFileState,
      })
   }

   const removedFileSuccess = (state: FilesProps, action: FilesActionProps) => {
      const { rootFolder, rootId } = action;
      const { payload } = action;
      const id = payload.id;
      if (!rootFolder || !rootId) return state;
      // Change state object based on rootFolder
      let updatedFileState = { ...state[rootFolder] };
      // Remove file from state
      if (updatedFileState[rootId] && updatedFileState[rootId][id]) {
         delete updatedFileState[rootId][id];
      }
      // Return updated state
      return updateObject(state, {
         loading: false,
         error: null,
         [rootFolder]: updatedFileState,
      })
   }

   const filesAPICallStart = (state: FilesProps, action: FilesActionProps) => {
      return updateObject(state, {
         loading: true,
         error: null,
      })
   }

   const filesAPICallFail = (state: FilesProps, action: FilesActionProps) => {
      return updateObject(state, {
         loading: false,
         error: action.error,
      })
   }

   const resetState = () => {
      return initialState;
   }

   switch (action.type) {
      case ADDED_FILE_SUCCESS: return addedFileSuccess(state, action);
      case MODIFIED_FILE_SUCCESS: return modifiedFileSuccess(state, action);
      case REMOVED_FILE_SUCCESS: return removedFileSuccess(state, action);

      case UPLOAD_FILE_SUCCESS: return uplodFileSuccess(state, action);
      case DOWNLOAD_FILE_SUCCESS: return downloadFileSuccess(state, action);
      case DELETE_FILE_SUCCESS: return deleteFileSuccess(state, action);

      case FILES_API_CALL_START: return filesAPICallStart(state, action);
      case FILES_API_CALL_FAIL: return filesAPICallFail(state, action);
      case FILES_RESET_STATE: return resetState();

      default: return state;
   }
}

export default files;