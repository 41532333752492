import { useContext } from 'react';
import AppSettingsContext from 'contexts/AppSettingsContext';
import { AppSettingsContextType } from 'types/appSettings';

// ==============================|| ESTATES - HOOKS  ||============================== //

const useAppSettings = () => {
    const context = useContext(AppSettingsContext) as AppSettingsContextType; // To ensure data and functions are not unknown when init is null;
    if (context === undefined) throw new Error("useAppSettings was used outside of its Provider");
    return context;
}

export default useAppSettings;