import { Box } from "@mui/material";

// ------------ POLY ------------
type PolyStyle = "Filled" | "Transparent";

export const POLY_OPTIONS_NON_PROGRAM_DEFINED = [
   { id: "Filled", label: "poly-filled" },
   { id: "Transparent", label: "poly-transparent" }
];

// Nature ------------
export const POLY_OPTIONS_NATURE = [
   { id: "Heath", label: "poly-heath" },
   { id: "Bog", label: "poly-forest-bog" },
   { id: "Meadow", label: "poly-meadow" },
   { id: "Coast", label: "poly-coast" },
   { id: "Pasture", label: "poly-pasture" },
   { id: "Lake", label: "poly-lake" },
   { id: "Marsh", label: "poly-marsh" }
];

// NYE
// Eng, Fredet områder, Hede, Mose, Overdrev, Strandeng, Sø, Sten og jorddiger, Vandløb, NATURA 2000

// Hede - Heath or Heathland
// Mose - Bog or Peatland
// Eng - Meadow
// Kyst - Coast or Shoreline
// Overdrev - Pasture or Grazed Grassland
// Sø - Lake or Pond (afhængigt af størrelsen)
// Sump - Marsh or Swamp


// Other ------------
export const POLY_OPTIONS_OTHER = [
   { id: "Building", label: "poly-building" },
   { id: "ParkGarden", label: "poly-park-garden" },
   { id: "Field", label: "poly-field" },
   { id: "Meadow", label: "poly-meadow" },
];

// Bygning - Building - #626262
// Park/have - Park/Garden - #ffffff
// Mark - Field or Farmland - #E7FFFF
// Eng - Meadow (bemærk, dette er den samme oversættelse som tidligere, da "eng" betyder "meadow" på både et naturligt og kultiveret perspektiv) - #ffffb9

export const getPolygonComponentById = (polyStyle: PolyStyle, dimension: number, color: string): JSX.Element | null => {
   const containerStyle = { display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: dimension, width: dimension };
   const style = {
      height: dimension, bgcolor: polyStyle === "Filled" ? color : null, border: polyStyle === "Filled" ? 1 : 3, borderColor: polyStyle === "Filled" ? 'secondary.400' : color,
      width: dimension,//polyStyle === 'Transparent' ? 6 : dimension
   }
   return <Box sx={containerStyle}><Box sx={{ ...style }} /></Box>;
}