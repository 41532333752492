import { Contact } from "types/auth";
import { EstateProps, MapProps, ProjectProps } from "types/estatesAndMap";

export interface GetContactOptions {
   userId: string | null,
   contacts: { [key: string]: Contact},
   selectedData: MapProps | ProjectProps | EstateProps | null,
   // selectedEstate?: EstateProps,
   // selectedProject?: ProjectProps,
   orgMapIds: string[],
   orgProjectIds: string[],
   personTypeLabelText: string,
}

const getContactOptions = ({userId, contacts, selectedData, orgMapIds, orgProjectIds, personTypeLabelText }: GetContactOptions) => {
   if (contacts) {
      return Object.entries(contacts).map(([id, el]: [string, Contact]) => {
         let disabled = true;
         // Check for name in contact, else set to email
         let name = el.email;
         if (el.firstName && el.lastName) {
            name = `${el.firstName} ${el.lastName} (${el.email})`
         }
         // Check if contact should be disabled based on selected data
         if (selectedData) {
            if (orgMapIds.includes(selectedData.id) || orgProjectIds.includes(selectedData.id)) {
               disabled = false;
            } else if (selectedData.roles && !selectedData.roles[el.id]) {
               disabled = false;
            } else if (selectedData.roles && selectedData.roles[el.id]) {
               if (selectedData.roles[el.id].inviterId === userId) {
                  disabled = false;
               }
            }
         } else {
            disabled = false;
         }
         return { 
            ...el, 
            id: id, 
            name: name, 
            label: name + ":" + el.email,
            personType: "contact" as any,
            personTypeLabelText,
            disabled
          }
      });
   }
   return null;
}

export default getContactOptions;