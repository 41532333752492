import React, { useState, useEffect, useRef, SyntheticEvent } from "react";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/system";

// project import
import { useTranslation } from "utils/locales/utilityFunctions";

interface HoldMenuItemProps {
  label: string;
  holdTime: number; // Hold time in milliseconds
  disabled?: boolean;
  onHoldComplete: () => void;
  onClick?: () => void;
}

const StyledMenuItem = styled(MenuItem)(({ progress }: { progress: number }) => ({
  background: `linear-gradient(to right, #dadada ${progress}%, transparent ${progress}%)`,
}));

const HoldMenuItem: React.FC<HoldMenuItemProps> = ({
  label,
  holdTime,
  disabled,
  onHoldComplete,
  onClick,
}) => {
  // --- Hooks --- //
  const { translate } = useTranslation();

  const [progress, setProgress] = useState(0);
  const [currentLabel, setCurrentLabel] = useState(label);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const clickTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const onHoldCompleteExecuted = useRef(false);

  useEffect(() => {
    if (progress >= 100 && !onHoldCompleteExecuted.current) {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current);
        timeoutRef.current = null;
      }
      onHoldComplete();
      onHoldCompleteExecuted.current = true;
      setCurrentLabel(label); // Reset the label
    }
  }, [progress, onHoldComplete, label]);

  const handleMouseDown = () => {
    setCurrentLabel(translate("button-hold"));
    timeoutRef.current = setInterval(() => {
      setProgress((prevProgress) => prevProgress + 100 / (holdTime / 100));
    }, 100);
  };

  const handleMouseUp = () => {
    if (timeoutRef.current) {
      clearInterval(timeoutRef.current);
      timeoutRef.current = null;
    }
    setProgress(0); // Reset the progress
    setCurrentLabel(label); // Reset the label
  };

  const handleClick = (event: SyntheticEvent) => {
    setCurrentLabel(translate("button-hold"));
    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current);
    }
    clickTimeoutRef.current = setTimeout(() => {
      setCurrentLabel(label); // Reset the label after 1 second
    }, 1000);
    if (onClick) {
      onClick();
    }
    event.stopPropagation();
  };

  return (
    <StyledMenuItem
      progress={progress}
      disabled={disabled}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onClick={handleClick}
    >
      {currentLabel}
    </StyledMenuItem>
  );
};

export default HoldMenuItem;
