// project import
import home from './home';
import map from './map';
import data from './data';
import settings from './settings';


import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [home, map, data, settings]
};

export default menuItems;
